import React from "react";
import Navbar from "../components/Navbar";
import { useHistory } from "react-router-dom";

const CongratulationPage = () => {
  const history = useHistory();

  const onClose = (e) => {
    e.preventDefault();
    history.push('/');
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4 text-2xl font-bold">
        <p className="mt-4">Congratulations,</p>
        <p className="mt-8 px-8 text-center">Thank you for submitting your Bid.</p>
        <p className="px-8 text-center">The Arrestee's Family /Friend will be notified and will contact you if selected.</p>
        <button
          className={`w-48 bg-primary text-white py-2 mt-8 rounded-full focus:outline-none`}
          onClick={(event) => onClose(event)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CongratulationPage;
