export default function InputWrapper({label, value, onChangeHandler, name, readOnly = false, placeholder = "", required = false}) {
  return (
    <div>
      <label htmlFor={name} className={`block text-sm ${required ? 'font-bold' : ''}`}>
        {`${label}${required ? ' (Required)' : ''}`}:
      </label>
      <input
        type="text"
        className={`my-2 p-1 w-full border border-black-400 ${required ? 'font-semibold bg-yellow-200' : ''}`}
        name={name}
        value={value}
        placeholder={placeholder}
        id={name}
        onChange={(event) => onChangeHandler(event)}
        readOnly={readOnly}
      />
    </div>
  );
}
