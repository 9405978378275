import React, { useState, useContext } from "react";
import Navbar from "../components/Navbar";
import { useHistory } from "react-router-dom";
import Dropdown from "react-dropdown";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../providers/Authentication";
import { database } from "../firebase";
import axios from "axios";

const DATA = [
  "1 County - $395 per month",
  "2 Counties - $695 per month",
  "3 Counties - $895 per month",
  "4 Counties - $995 per month",
];

const PRICES = [
  "price_1L8S9zIBGLX9a8upnCu6p8By",
  "price_1L8SMrIBGLX9a8upWcoIHgnY",
  "price_1L8SO2IBGLX9a8upgEwBcCkb",
  "price_1L8SOvIBGLX9a8upaWYOo2eA",
];

const COUNTIES = [
  " ",
  "Louisiana - Orleans Parish",
  "Oklahoma - Cleveland County",
  "Oklahoma - Oklahoma County",
  "Oklahoma - Tulsa County",
  "Texas - Bexar County",
  "Texas - Collin County",
  "Texas - Comal County",
  "Texas - Dallas County",
  "Texas - Denton County",
  "Texas - Harris County",
  "Texas - Montgomery County",
  "Texas - Tarrant County",
  "Texas - Travis County",
  "Texas - Williamson County",
];

const SubscriptionPage = () => {
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const [option, setOption] = useState(currentUser.Subscription || "");
  const [indicator, setIndicator] = useState(false);
  const [subId, setSubId] = useState(currentUser.StripeSubscriptionId || "");
  const [selectedCounties, setSelectedCounties] = useState(
    currentUser.SelectedCounties || []
  );
  const stripe = useStripe();
  const elements = useElements();

  const onSubscribe = async (e) => {
    e.preventDefault();

    if (elements == null) {
      return;
    }

    if (!subId) {
      setIndicator(true);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (!error && paymentMethod) {
        axios
          .post(
            "https://us-central1-bailbondbidsnow-9ec7c.cloudfunctions.net/createStripeCustomer",
            {
              paymentMethodId: paymentMethod.id,
              name: currentUser.Name || "",
              email: currentUser.Email || "",
              phone: currentUser.Phone || "",
            },
            { headers: { "Content-Type": "application/json" } }
          )
          .then(async (response) => {
            if (response && response.data && response.data.success) {
              const customerId = response.data.customerId;
              axios
                .post(
                  "https://us-central1-bailbondbidsnow-9ec7c.cloudfunctions.net/createStripeSubscription",
                  {
                    customerId,
                    priceId: PRICES[DATA.indexOf(option)],
                    paymentMethodId: paymentMethod.id,
                  },
                  { headers: { "Content-Type": "application/json" } }
                )
                .then(async (response1) => {
                  setIndicator(false);
                  if (response1 && response1.data && response1.data.success) {
                    const subscriptionId = response1.data.subscriptionId;
                    const itemId = response1.data.itemId;
                    setSubId(subscriptionId);
                    const bcRef = database.ref(`BCT/${currentUser.UserUid}`);
                    await bcRef.update({
                      Subscription: option,
                      StripeCustomerId: customerId,
                      StripeSubscriptionId: subscriptionId,
                      StripeItemId: itemId,
                      SelectedCounties: [],
                    });
                    toast("Subscription Success!");
                  }
                })
                .catch((error) => setIndicator(false));
            }
          })
          .catch((error) => setIndicator(false));
      } else {
        setIndicator(false);
      }
    } else {
      setIndicator(true);

      axios
        .post(
          "https://us-central1-bailbondbidsnow-9ec7c.cloudfunctions.net/updateStripeSubscription",
          {
            itemId: currentUser.StripeItemId || "",
            priceId: PRICES[DATA.indexOf(option)],
            subId,
          },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(async (response1) => {
          setIndicator(false);
          if (response1 && response1.data && response1.data.success) {
            const bcRef = database.ref(`BCT/${currentUser.UserUid}`);
            await bcRef.update({
              Subscription: option,
              SelectedCounties: [],
            });
            toast("Upgrade/Downgrade Subscription Success!");
            // setTimeout(() => history.push("/"), 1500);
          }
        })
        .catch((error) => setIndicator(false));
    }
  };

  const onSetCounties = async (e) => {
    e.preventDefault();
    const bcRef = database.ref(`BCT/${currentUser.UserUid}`);
    await bcRef.update({
      SelectedCounties: selectedCounties,
    });
    toast("Successfully selected counties!");
    setTimeout(() => history.push("/"), 1500);
  };

  const onSelect = (value) => {
    if (value && value.value) setOption(value.value);
  };

  return (
    <div className="w-full flex flex-col">
      <Navbar />
      <div className="flex flex-col items-center py-4 w-full mt-12">
        Please choose a subscription you want.
        <Dropdown
          options={DATA}
          onChange={onSelect}
          value={option}
          placeholder="Choose one..."
          className="mt-2 mb-4 w-96"
        />
        {!subId && (
          <div className="w-96">
            <CardElement />
          </div>
        )}
        <button
          className={`w-48 ${
            stripe && elements && option && !indicator
              ? "bg-primary"
              : "bg-gray-500"
          } text-white py-2 mt-8 rounded-full focus:outline-none`}
          onClick={(event) => onSubscribe(event)}
          disabled={!stripe || !elements || !option || indicator}
        >
          {indicator
            ? "Please wait..."
            : subId
            ? "Upgrade / Downgrade"
            : "Subscribe"}
        </button>
        {subId && (
          <div className="mt-8">
            {COUNTIES.map((county) => (
              <label className="block my-2">
                <input
                  type="checkbox"
                  name={county}
                  checked={selectedCounties.indexOf(county) > -1}
                  onChange={() => {
                    const temp = [...selectedCounties];
                    if (selectedCounties.indexOf(county) > -1) {
                      temp.splice(selectedCounties.indexOf(county), 1);
                    } else if (
                      selectedCounties.length <
                      DATA.indexOf(currentUser.Subscription) + 1
                    ) {
                      temp.push(county);
                    }
                    setSelectedCounties(temp);
                  }}
                />
                &nbsp;&nbsp;&nbsp;{county}
              </label>
            ))}
            <button
              className={`w-48 ${
                selectedCounties.length ===
                DATA.indexOf(currentUser.Subscription) + 1
                  ? "bg-primary"
                  : "bg-gray-500"
              } text-white py-2 mt-8 rounded-full focus:outline-none`}
              onClick={(event) => onSetCounties(event)}
              disabled={selectedCounties.length !== DATA.indexOf(option) + 1}
            >
              Set Counties
            </button>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default SubscriptionPage;
