import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import HomePage from "../pages/HomePage";
import PasswordReset from "../pages/PasswordReset";
import ProfilePage from "../pages/ProfilePage";
import JobsPage from "../pages/JobsPage";
import MyJobsPage from "../pages/MyJobsPage";
import ContactUsPage from "../pages/ContactUsPage";
import AssistantPage from "../pages/AssistantPage";
import TermsPage from "../pages/TermsPage";
import BidPage from "../pages/BidPage";
import CongratulationPage from "../pages/CongratulationPage";
import CurrentBidsPage from "../pages/CurrentBidsPage";
import ViewProfilePage from "../pages/ViewProfilePage";
import OfferPage from "../pages/OfferPage";
import PostSuccessPage from "../pages/PostSuccessPage";
import OfferSentPage from "../pages/OfferSentPage";
import SelectedBidsPage from "../pages/SelectedBidsPage";
import SubscriptionPage from "../pages/SubscriptionPage";

function PublicRoute() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/passwordreset" component={PasswordReset} />
        <Route exact path="/assistant" component={AssistantPage} />
        <Route exact path="/terms" component={TermsPage} />
        <Route exact path="/contactus" component={ContactUsPage} />
        <PrivateRoute exact path="/profile" component={ProfilePage} />
        <PrivateRoute exact path="/jobs" component={JobsPage} />
        <PrivateRoute exact path="/myjobs" component={MyJobsPage} />
        <PrivateRoute exact path="/bid" component={BidPage} />
        <PrivateRoute
          exact
          path="/congratulation"
          component={CongratulationPage}
        />
        <PrivateRoute exact path="/currentbids" component={CurrentBidsPage} />
        <PrivateRoute exact path="/viewprofile" component={ViewProfilePage} />
        <PrivateRoute exact path="/offer" component={OfferPage} />
        <PrivateRoute exact path="/postsuccess" component={PostSuccessPage} />
        <PrivateRoute exact path="/offersent" component={OfferSentPage} />
        <PrivateRoute exact path="/selectedbids" component={SelectedBidsPage} />
        <PrivateRoute exact path="/subscription" component={SubscriptionPage} />
      </Switch>
    </Router>
  );
}

export default PublicRoute;
