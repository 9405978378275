import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import avatar from "../assets/image/avatar.png";
import { database } from "../firebase";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import InputWrapper from "../components/InputWrapper";
import { useHistory, useLocation } from "react-router-dom";

const DATA = [
  " ",
  "Louisiana - Orleans Parish",
  "Oklahoma - Cleveland County",
  "Oklahoma - Oklahoma County",
  "Oklahoma - Tulsa County",
  "Texas - Bexar County",
  "Texas - Collin County",
  "Texas - Comal County",
  "Texas - Dallas County",
  "Texas - Denton County",
  "Texas - Harris County",
  "Texas - Montgomery County",
  "Texas - Tarrant County",
  "Texas - Travis County",
  "Texas - Williamson County",
];

const ViewProfilePage = () => {
  const history = useHistory();
  const {
    state: { ffUserUid, bidAvailability },
  } = useLocation();
  const [icon, setIcon] = useState(null);
  const [ffsr, setFFSR] = useState({
    Name: "",
    Phone: "",
    Email: "",
    Birthday: "",
    Address: "",
    ID: "",
    Relationship: "",
    EmployeerName: "",
    EmployeerPhone: "",
    EmployeerHowLong: "",
    IsAvailable: true,
    Collateral: "",
    AlternateName: "",
    AlternatePhone: "",
    AlternateEmail: "",
  });
  const [bondPromisor, setBondPromisor] = useState({
    Name: "",
    Phone: "",
    Email: "",
    Birthday: "",
    Address: "",
    ID: "",
    Relationship: "",
    EmployeerName: "",
    EmployeerPhone: "",
    EmployeerHowLong: "",
    IsAvailable: true,
    Collateral: "",
    AlternateName: "",
    AlternatePhone: "",
    AlternateEmail: "",
  });
  const [arrestee, setArrestee] = useState({
    Name: "",
    Phone: "",
    Email: "",
    Birthday: "",
    Address: "",
    Citizenship: "",
    ImmigrationStatus: "",
    SSN: "",
    ID: "",
    Relationship: "",
    EmployeerName: "",
    EmployeerPhone: "",
    EmployeerHowLong: "",
    SID: "",
    Agency: "",
    Facility: "",
    PriorArrests: "",
    CriminalHistory: "",
    PriorPeriods: "",
    AdditionalName1: "",
    AdditionalName2: "",
    AdditionalPhone1: "",
    AdditionalPhone2: "",
    AdditionalRelationship1: "",
    AdditionalRelationship2: "",
    AdditionalEmail1: "",
    AdditionalEmail2: "",
  });

  useEffect(() => {
    const getProfileData = async () => {
      const snapshot1 = await database.ref(`FFSRT/${ffUserUid}`).once("value");
      setIcon(snapshot1.val().IconUpload);
      setFFSR(snapshot1.val().FFSR);
      setBondPromisor(snapshot1.val().BondPromisor);
      setArrestee(snapshot1.val().Arrestee);
    };

    getProfileData();
    // eslint-disable-next-line
  }, []);

  const onClose = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <div className="w-full flex flex-col">
      <Navbar />
      <div className="flex flex-col items-center py-4">
        <img
          src={icon ? icon : avatar}
          alt="..."
          className="w-32 h-32 rounded-full"
        />
      </div>
      <div className="mx-auto w-11/12 md:w-2/4 rounded py-4 px-4 md:px-8 flex flex-col">
        <>
          <p className="text-center font-bold mt-8">
            Family/Friend Seeking Release
          </p>
          <InputWrapper
            label="Family/Friend's Name"
            name="ffsrName"
            value={ffsr.Name}
            readOnly
          />
          <InputWrapper
            label="Family/Friend's Phone"
            name="ffsrPhone"
            value={!bidAvailability ? "" : ffsr.Phone || "N/A"}
            placeholder="Viewable After Bid Submission"
            readOnly
          />
          <InputWrapper
            label="Family/Friend's Preferred Email"
            name="ffsrEmail"
            value={!bidAvailability ? "" : ffsr.Email || "N/A"}
            placeholder="Viewable After Bid Submission"
            readOnly
          />
          <InputWrapper
            label="Family/Friend's Date of Birth"
            name="ffsrBirthday"
            value={ffsr.Birthday}
            readOnly
          />
          <InputWrapper
            label="Family/Friend's Address"
            name="ffsrAddress"
            value={ffsr.Address}
            readOnly
          />
          <InputWrapper
            label="Family/Friend's Driver License or ID State and Number"
            name="ffsrID"
            value={ffsr.ID}
            readOnly
          />
          <InputWrapper
            label="Family/Friend's Relationship to Arrestee"
            name="ffsrRelationship"
            value={ffsr.Relationship}
            readOnly
          />
          <InputWrapper
            label="Family/Friend's Current Employer"
            name="ffsrEmployeerName"
            value={ffsr.EmployeerName}
            readOnly
          />
          <InputWrapper
            label="Family/Friend's Current Employer's Phone"
            name="ffsrEmployeerPhone"
            value={ffsr.EmployeerPhone}
            readOnly
          />
          <InputWrapper
            label="How Long at Current Employer"
            name="ffsrEmployeerHowLong"
            value={ffsr.EmployeerHowLong}
            readOnly
          />
          {ffsr.IsAvailable && (
            <InputWrapper
              label="If so, Please Describe Available Collateral (ie...vehicle, bank account, home, etc.)"
              name="ffsrCollateral"
              value={ffsr.Collateral}
              readOnly
            />
          )}
          <InputWrapper
            label="Alternate Contact Person’s Name"
            name="ffsrAlternateName"
            value={ffsr.AlternateName}
            readOnly
          />
          <InputWrapper
            label="Alternate Contact Person’s Phone"
            name="ffsrAlternatePhone"
            value={!bidAvailability ? "" : ffsr.AlternatePhone || "N/A"}
            placeholder="Viewable After Bid Submission"
            readOnly
          />
          <InputWrapper
            label="Alternate Contact Person’s Email"
            name="ffsrAlternateEmail"
            value={!bidAvailability ? "" : ffsr.AlternateEmail || "N/A"}
            placeholder="Viewable After Bid Submission"
            readOnly
          />

          <p className="text-center font-bold mt-8">
            Bond Promisor or Co-Signer (Person who promises or also promises to
            repay bond)
          </p>
          <>
            <InputWrapper
              label="Promisor’s Name"
              name="promisorName"
              value={bondPromisor.Name}
              readOnly
            />
            <InputWrapper
              label="Promisor's Phone"
              name="promisorPhone"
              value={!bidAvailability ? "" : bondPromisor.Phone || "N/A"}
              placeholder="Viewable After Bid Submission"
              readOnly
            />
            <InputWrapper
              label="Promisor's Preferred Email"
              name="promisorEmail"
              value={!bidAvailability ? "" : bondPromisor.Email || "N/A"}
              placeholder="Viewable After Bid Submission"
              readOnly
            />
            <InputWrapper
              label="Promisor's Date of Birth"
              name="promisorBirthday"
              value={bondPromisor.Birthday}
              readOnly
            />
            <InputWrapper
              label="Promisor's Address"
              name="promisorAddress"
              value={bondPromisor.Address}
              readOnly
            />
            <InputWrapper
              label="Promisor's Driver License or ID State and Number"
              name="promisorID"
              value={bondPromisor.ID}
              readOnly
            />
            <InputWrapper
              label="Relationship to Arrestee"
              name="promisorRelationship"
              value={bondPromisor.Relationship}
              readOnly
            />
            <InputWrapper
              label="Promisor's Current Employer"
              name="promisorEmployeerName"
              value={bondPromisor.EmployeerName}
              readOnly
            />
            <InputWrapper
              label="Promisor's Current Employer's Phone"
              name="promisorEmployeerPhone"
              value={bondPromisor.EmployeerPhone}
              readOnly
            />
            <InputWrapper
              label="Promisor’s Time at Current Employer"
              name="promisorEmployeerHowLong"
              value={bondPromisor.EmployeerHowLong}
              readOnly
            />
            {bondPromisor.IsAvailable && (
              <InputWrapper
                label="If so, Please Describe Available Collateral (ie...vehicle, bank account, home, etc.)"
                name="promisorCollateral"
                value={bondPromisor.Collateral}
                readOnly
              />
            )}
            <InputWrapper
              label="Promisor's Alternate Contact Person’s Name"
              name="promisorAlternateName"
              value={bondPromisor.AlternateName}
              readOnly
            />
            <InputWrapper
              label="Promisor's Alternate Contact Person’s Phone"
              name="promisorAlternatePhone"
              value={
                !bidAvailability ? "" : bondPromisor.AlternatePhone || "N/A"
              }
              placeholder="Viewable After Bid Submission"
              readOnly
            />
            <InputWrapper
              label="Promisor's Alternate Contact Person’s Email"
              name="promisorAlternateEmail"
              value={
                !bidAvailability ? "" : bondPromisor.AlternateEmail || "N/A"
              }
              placeholder="Viewable After Bid Submission"
              readOnly
            />
          </>

          <p className="text-center font-bold mt-8">Arrestee's Information</p>
          <InputWrapper
            label="Arrestee's Name"
            name="arresteeName"
            value={arrestee.Name}
            readOnly
          />
          <InputWrapper
            label="Arrestee's Phone"
            name="arresteePhone"
            value={!bidAvailability ? "" : arrestee.Phone || "N/A"}
            placeholder="Viewable After Bid Submission"
            readOnly
          />
          <InputWrapper
            label="Arrestee's Preferred Email"
            name="arresteeEmail"
            value={!bidAvailability ? "" : arrestee.Email || "N/A"}
            placeholder="Viewable After Bid Submission"
            readOnly
          />
          <InputWrapper
            label="Arrestee's Date of Birth"
            name="arresteeBirthday"
            value={arrestee.Birthday}
            readOnly
          />
          <InputWrapper
            label="Arrestee's Address"
            name="arresteeAddress"
            value={arrestee.Address}
            readOnly
          />
          <InputWrapper
            label="Arrestee's Citizenship"
            name="arresteeCitizenship"
            value={arrestee.Citizenship}
            readOnly
          />
          <InputWrapper
            label="Arrestee's Immigration Status"
            name="arresteeImmigrationStatus"
            value={arrestee.ImmigrationStatus}
            readOnly
          />
          <InputWrapper
            label="Arrestee's Social Security Number"
            name="arresteeSSN"
            value={arrestee.SSN}
            readOnly
          />
          <InputWrapper
            label="Arrestee's Driver License or ID State and Number"
            name="arresteeID"
            value={arrestee.ID}
            readOnly
          />
          <InputWrapper
            label="Arrestee Relationship to You"
            name="arresteeRelationship"
            value={arrestee.Relationship}
            readOnly
          />
          <InputWrapper
            label="Arrestee's Current Employer"
            name="arresteeEmployeerName"
            value={arrestee.EmployeerName}
            readOnly
          />
          <InputWrapper
            label="Arrestee's Current Employer Phone"
            name="arresteeEmployeerPhone"
            value={arrestee.EmployeerPhone}
            readOnly
          />
          <InputWrapper
            label="Arrestee’s Length at Current Employer"
            name="arresteeEmployeerHowLong"
            value={arrestee.EmployeerHowLong}
            readOnly
          />
          <InputWrapper
            label="Arrestee’s SID Number if Known"
            name="arresteeSID"
            value={arrestee.SID}
            readOnly
          />
          <InputWrapper
            label="Arrestee’s Arresting Agency/Department if Known"
            name="arresteeAgency"
            value={arrestee.Agency}
            readOnly
          />
          <label htmlFor="facility" className="block text-sm">
            Arrestee’s Current Jail/Detention Facility:
          </label>
          <Dropdown
            options={DATA}
            value={arrestee.Facility}
            placeholder="Please choose the location."
            className="mt-2 mb-4 w-full"
            controlClassName="h-10"
          />
          <InputWrapper
            label="Arrestee's Current Charge(s) if Known"
            name="arresteeCurrentCharges"
            value={arrestee.CurrentCharges}
            readOnly
          />
          <InputWrapper
            label="Any Arrestee’s Known Prior Arrests if Known"
            name="arresteePriorArrests"
            value={arrestee.PriorArrests}
            readOnly
          />
          <InputWrapper
            label="Any Arresttee’s Known Criminal History if Known"
            name="arresteeCriminalHistory"
            value={arrestee.CriminalHistory}
            readOnly
          />
          <InputWrapper
            label="Any Arrestee’s Prior Periods of Prison Incarceration if Known"
            name="arresteePriorPeriods"
            value={arrestee.PriorPeriods}
            readOnly
          />
          <InputWrapper
            label="Arrestee’s 1st Additional Contact Person’s Name"
            name="arresteeAdditionalName1"
            value={arrestee.AdditionalName1}
            readOnly
          />
          <InputWrapper
            label="Arrestee’s 1st Additional Contact Person’s Relationship to arrestee"
            name="arresteeAdditionalRelationship1"
            value={arrestee.AdditionalRelationship1}
            readOnly
          />
          <InputWrapper
            label="Arrestee’s 1st Additional Alternate Contact’s Person Phone"
            name="arresteeAdditionalPhone1"
            value={!bidAvailability ? "" : arrestee.AdditionalPhone1 || "N/A"}
            placeholder="Viewable After Bid Submission"
            readOnly
          />
          <InputWrapper
            label="Arrestee’s 1st Additional Alternate Contact’s Person Email"
            name="arresteeAdditionalEmail1"
            value={!bidAvailability ? "" : arrestee.AdditionalEmail1 || "N/A"}
            placeholder="Viewable After Bid Submission"
            readOnly
          />
          <InputWrapper
            label="Arrestee’s 2nd Additional Contact Person’s Name"
            name="arresteeAdditionalName2"
            value={arrestee.AdditionalName2}
            readOnly
          />
          <InputWrapper
            label="Arrestee’s 2nd Additional Contact Person’s Relationship to arrestee"
            name="arresteeAdditionalRelationship2"
            value={arrestee.AdditionalRelationship2}
            readOnly
          />
          <InputWrapper
            label="Arrestee’s 2nd Additional Alternate Contact’s Person Phone"
            name="arresteeAdditionalPhone2"
            value={!bidAvailability ? "" : arrestee.AdditionalPhone2 || "N/A"}
            placeholder="Viewable After Bid Submission"
            readOnly
          />
          <InputWrapper
            label="Arrestee’s 2nd Additional Alternate Contact’s Person Email"
            name="arresteeAdditionalEmail2"
            value={!bidAvailability ? "" : arrestee.AdditionalEmail2 || "N/A"}
            placeholder="Viewable After Bid Submission"
            readOnly
          />
        </>

        <button
          className="bg-primary hover:opacity-80 w-full py-2 text-white font-bold"
          onClick={(event) => onClose(event)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ViewProfilePage;
