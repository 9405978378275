import React, { useContext, useState } from "react";
import Navbar from "../components/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../providers/Authentication";
import { database } from "../firebase";
import avatar from "../assets/image/avatar.png";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const OfferPage = () => {
  const {
    state: { bid },
  } = useLocation();
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [description, setDescription] = useState("");
  const sEmail = bid ? bid.sEmail : "";
  const sName = bid ? bid.sName : "";
  const sUid = bid ? bid.sUid : "";

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "description") {
      setDescription(value);
    }
  };

  const contactBC = (e) => {
    e.preventDefault();

    const message =
      "Your message has been sent to the selected Bondsman. They will be contacting you shortly. BailBondBidsNow.com";
    axios
      .post(
        "https://us-central1-subworknow.cloudfunctions.net/sendSMS1",
        { to: currentUser.FFSR.Phone, body: message },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(async function (response) {
      })
      .catch(function (error) {
      });

    if (bid && bid.sPhone) {
      const message1 = `Hello. One of your Selected Bids regarding ${currentUser.Arrestee.Name} is requesting that you contact them. Please review the "Selected Bids" section. Truly, BailBondBidsNow.com`;
      axios
        .post(
          "https://us-central1-subworknow.cloudfunctions.net/sendSMS1",
          { to: bid.sPhone, body: message1 },
          { headers: { "Content-Type": "application/json" } }
        )
        .then(async function (response) {
        })
        .catch(function (error) {
        });
    }

    if (bid && bid.sEmail) {
      const msg = {
        personalizations: [
          {
            from: {
              email: "rey@bailbondbidsnow-outreach.com",
              name: "BailBondBidsNow",
            },
            to: [
              {
                email: bid.sEmail,
                name: bid.sName,
              },
            ],
          },
        ],
        from: {
          email: "rey@bailbondbidsnow-outreach.com",
          name: "BailBondBidsNow",
        },
        reply_to: {
          email: "rey@bailbondbidsnow-outreach.com",
          name: "BailBondBidsNow",
        },
        subject: "Congratulations",
        content: [
          {
            type: "text/html",
            value: `<!doctype html>
          <html>
            <head>
              <meta name="viewport" content="width=device-width" />
              <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
              <title>Congratulations</title>
              <style>
                body {
                  background-color: #f6f6f6;
                  font-family: sans-serif;
                  -webkit-font-smoothing: antialiased;
                  font-size: 14px;
                  line-height: 1.4;
                  margin: 0;
                  padding: 0;
                  -ms-text-size-adjust: 100%;
                  -webkit-text-size-adjust: 100%; 
                }
                p {
                  font-family: sans-serif;
                  font-size: 14px;
                  font-weight: normal;
                  margin: 0;
                  margin-bottom: 15px; 
                }
              </style>
            </head>
            <body>
              <p>Hello,</p>
              <p>Hello. One of your Selected Bids regarding ${currentUser.Arrestee.Name} is requesting that you contact them. Please review the "Selected Bids" section. Truly, BailBondBidsNow.com</p>
              <p>Truly,</p>
              <p>BailBondBidsNow.com</p>
            </body>
          </html>
          `,
          },
        ],
      };

      axios
        .post(
          "https://us-central1-subworknow.cloudfunctions.net/sendEmail",
          msg,
          { headers: { "Content-Type": "application/json" } }
        )
        .then(function (response) {
        })
        .catch(function (error) {
        });
    }
    setIsOpen(true);
  };

  const onHire = async (e) => {
    e.preventDefault();
    try {
      const postKey = database.ref().child("OT").push().key;
      database
        .ref(`OT/${postKey}`)
        .set({
          BidId: bid.BidId,
          UserUid: currentUser.UserUid,
          Description: description,
          OfferId: postKey,
          SCUid: sUid,
          isReplied: false,
        })
        .then((res) => {
          const msg = {
            personalizations: [
              {
                from: {
                  email: "rey@bailbondbidsnow-outreach.com",
                  name: "BailBondBidsNow",
                },
                to: [
                  {
                    email: sEmail,
                    name: sName,
                  },
                ],
              },
            ],
            from: {
              email: "rey@bailbondbidsnow-outreach.com",
              name: "BailBondBidsNow",
            },
            reply_to: {
              email: "rey@bailbondbidsnow-outreach.com",
              name: "BailBondBidsNow",
            },
            subject: "Congratulations",
            content: [
              {
                type: "text/html",
                value: `<!doctype html>
              <html>
                <head>
                  <meta name="viewport" content="width=device-width" />
                  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                  <title>Congratulations</title>
                  <style>
                    body {
                      background-color: #f6f6f6;
                      font-family: sans-serif;
                      -webkit-font-smoothing: antialiased;
                      font-size: 14px;
                      line-height: 1.4;
                      margin: 0;
                      padding: 0;
                      -ms-text-size-adjust: 100%;
                      -webkit-text-size-adjust: 100%; 
                    }
                    p {
                      font-family: sans-serif;
                      font-size: 14px;
                      font-weight: normal;
                      margin: 0;
                      margin-bottom: 15px; 
                    }
                  </style>
                </head>
                <body class="">
                  <p>Hello,</p>
                  <p>One of your Selected Bids have sent you a message as follows: ${description}</p>
                  <p>Please review the Selected Bids section.</p>
                  <p>Truly,</p>
                  <p>BailBondBidsNow.com</p>
                </body>
              </html>
              `,
              },
            ],
          };

          axios
            .post(
              "https://us-central1-subworknow.cloudfunctions.net/sendEmail",
              msg,
              { headers: { "Content-Type": "application/json" } }
            )
            .then(function (response) {
            })
            .catch(function (error) {
            });

          if (bid && bid.sPhone) {
            const message1 = `Hello. One of your Selected Bids have sent you a message as follows: ${description}. Please review the Selected Bids section. Truly, BailBondBidsNow.com`;
            axios
              .post(
                "https://us-central1-subworknow.cloudfunctions.net/sendSMS1",
                { to: bid.sPhone, body: message1 },
                { headers: { "Content-Type": "application/json" } }
              )
              .then(async function (response) {
              })
              .catch(function (error) {
              });
          }
          history.push("/offersent");
        })
        .catch((err) => {
        });
    } catch (err) {
    }
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4">
        <div className="px-4 py-2 my-2 flex items-center">
          <img
            src={bid.icon ? bid.icon : avatar}
            alt="..."
            className="w-16 h-16 rounded-full"
          />
          <div className="ml-4">
            <p>Bonding Company Name: {bid.sName}</p>
            <p>Bonding Company Email: {bid.sEmail}</p>
            <p>Bonding Company Phone: {bid.sPhone}</p>
          </div>
        </div>
        Send Message:
        <textarea
          className="mt-2 p-2 w-96 h-36 border border-black-400 mb-4"
          name="description"
          value={description}
          placeholder=""
          id="description"
          onChange={(event) => onChangeHandler(event)}
        />
        <button
          className={`w-72 ${
            !description ? "bg-gray-500" : "bg-primary"
          } text-white py-2 my-3 rounded-full focus:outline-none`}
          onClick={(event) => onHire(event)}
          disabled={!description}
        >
          Send Message
        </button>
        <button
          className={`w-80 bg-primary text-white py-2 my-3 rounded-full focus:outline-none text-sm`}
          onClick={(event) => contactBC(event)}
        >
          Request Bonding Company to Contact Me
        </button>
      </div>
      <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
        <p className="text-lg">
          Your selected Bonding Company will be reaching out to You shortly.
        </p>
        <div className="flex justify-center">
          <button
            className={`w-24 bg-primary text-white py-1 my-3 mr-3 rounded-full focus:outline-none`}
            onClick={() => setIsOpen(false)}
          >
            OK
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default OfferPage;
