import React, { useContext, useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import { AuthContext } from "../providers/Authentication";
import { database } from "../firebase";
import avatar from "../assets/image/avatar.png";
import { ThreeBounce } from "better-react-spinkit";

const CurrentBidsPage = () => {
  const {
    state: { job },
  } = useLocation();
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const [indicator, setIndicator] = useState(false);
  const [bids, setBids] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setIndicator(true);
      const gEmail = currentUser.Email;
      const gPhone = currentUser.Phone;

      let tempBids = [];
      let updatedBids = [];

      await database.ref("BT").once("value", (snapshot) => {
        if (!!snapshot.val()) {
          snapshot.forEach((child) => {
            if (child.val().BidId === job.BidId) {
              tempBids.push(child.val());
            }
          });
        }
      });

      let item = {};
      for (item of tempBids) {
        let hireAvailability = false;
        let tempUserId = item.UserUid;
        const snapshot1 = await database.ref(`BCT/${tempUserId}`).once("value");
        if (!!snapshot1.val()) {
          const icon = snapshot1.val().IconUpload;
          const sEmail = snapshot1.val().Email;
          const sName = snapshot1.val().Name;
          const sPhone = snapshot1.val().Phone;
          const sUid = item.UserUid;
          let tempBidId = item.BidId;
          await database
            .ref("OT")
            .once("value", (snapshot3) => {
              if (!!snapshot3.val()) {
                snapshot3.forEach((child) => {
                  if (
                    tempBidId === child.val().BidId &&
                    currentUser.UserUid === child.val().UserUid
                  ) {
                    hireAvailability = true;
                  }
                });
              }
            })
            // eslint-disable-next-line
            .then(() => {
              if (job.JobId === item.JobId) {
                const newItem = {
                  ...item,
                  icon,
                  sEmail,
                  gEmail,
                  hireAvailability,
                  gPhone,
                  sPhone,
                  sName,
                  sUid,
                };
                updatedBids.push(newItem);
              }
            });
        }
      }
      setBids(updatedBids);
      setIndicator(false);
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  const onPressHire = (e, bid) => {
    e.preventDefault();
    history.push({
      pathname: "offer",
      state: { bid },
    });
  };

  return (
    <div className="w-full">
      <Navbar />
      {indicator ? (
        <div className="flex justify-center items-center flex-col my-80">
          <ThreeBounce size={24} color="#03a9f4" />
          Please wait...
        </div>
      ) : (
        <div className="flex flex-col items-center py-4">
          {bids.length === 0 ? (
            <p>Any posted Bids will appear momentarily.</p>
          ) : (
            bids.map((bid) => (
              <div className="border px-4 py-2 my-2" key={bid.BidId}>
                <div className="flex items-center">
                  <img
                    src={bid.icon ? bid.icon : avatar}
                    alt="..."
                    className="w-16 h-16 rounded-full"
                  />
                  <div className="ml-4">
                    <p>Bonding Company Name: {bid.sName}</p>
                    <p>Bonding Company Phone: {bid.sPhone}</p>
                    <p>Bonding Company Email: {bid.sEmail}</p>
                    <p>Bonding Company Total Bond Price: {bid.TotalBondPrice}</p>
                    <p>Bonding Company Payment Plan Terms: {bid.PaymentPlanTerms}</p>
                    <p>Bonding Company Required Collateral: {bid.Collateral}</p>
                    <p>Bonding Company Timeline to File Bond: {bid.Timeline}</p>
                  </div>
                </div>
                <div className="flex items-center justify-evenly">
                  <button
                    className={`w-24 ${
                      bid.hireAvailability ? "bg-gray-500" : "bg-primary"
                    } text-white py-2 my-3 rounded-full focus:outline-none`}
                    onClick={(event) => onPressHire(event, bid)}
                    disabled={bid.hireAvailability}
                  >
                    Select
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default CurrentBidsPage;
