const ToS = () => {
  return (
    <div className="flex flex-col h-40 overflow-y-auto mb-4">
      <p className="mt-3">
        The following terms and conditions govern all use of the
        BailBondBidsNow.com website and all content, services, available
        through the website and the mobile application, including, but not
        limited to, the client area (collectively referred to as the BBBN).
      </p>
      <p className="mt-3">
        BBBN is owned and operated by Viability Health Services, Inc. (dba: CAYO
        Holdings, Inc. (
        <a
          className="text-blue-500 underline"
          href="http://www.mayoholdings.com/"
        >
          WWW.CAYOHOLDINGS
        </a>
        ) (collectively referred to as CAYO)). BBBN is offered subject to your
        acceptance without modification of all of the terms and conditions
        contained herein and all other operating rules, policies including,
        without limitation, the CAYO privacy policy and procedures that may be
        published from time to time on BBBN by CAYO (collectively, the
        "Agreement"). Please read this Agreement carefully before accessing or
        using BBBN. By accessing or using any part of the website and/or the
        mobile application, you agree to become bound by the terms and
        conditions of this agreement.
      </p>
      <p className="text-lg font-bold mt-5">TERMS & CONDITIONS</p>
      <p className="mt-3">
        By downloading or using the website and the mobile application platform,
        the following terms and conditions (“Terms of Use”) constitute an
        agreement between you and BBBN and the services you use on our website
        and/or our mobile application (“Services”). You have read, and
        understand, and agree to these Terms of Use and our Privacy Policy and
        acknowledge that you, the individual are at least 18 years old. If you
        do not agree to these Terms of Use and the Privacy Notice, then you may
        not access BBBN and not use these Services.
      </p>
      <p className="text-lg font-bold mt-5">CHANGES TO TERMS OF USE</p>
      <p className="mt-3">
        We may revise and update these Terms of Use by posting a revised
        version. Any changes to the Terms will be effective immediately upon
        posting. Your continued use of the Services following the posting of
        revised Terms of Use means that you review, accept and agree to the
        changes each time. Your continued use of Services after such changes
        will constitute acceptance of, and agreement to, such changes. You waive
        any right you may have to receive specific notice of such changes.
      </p>
      <p className="text-lg font-bold mt-5">SCOPE AND RESTRICTION ON USE</p>
      <p className="mt-3">
        Subject to these Terms of Use, BBBN, grants you a limited,
        non-exclusive, non-transferable, non-sub-licensable, revocable license
        to access and use the Services for legitimate business purposes,
        including any graphics, text, instructions, images, audio files and/or
        other sounds, videos, and other materials you may view on, access
        through, or are otherwise related to the Services (collectively, the
        “Content”). Except as otherwise provided in these Terms of Use, the
        Content may not be copied, downloaded, or stored in a retrieval system
        for any other purpose, nor may it be redistributed, reused, or modified
        for any purpose, without the express written permission of BBBN. You
        agree not to:
      </p>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(a)</p>
        <p className="mt-3">
          collect information from the Services using an automated software tool
          or manually on a mass basis;
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(b)</p>
        <p className="mt-3">
          use automated means to access the Services, or gain unauthorized
          access to the Services or to any account or computer system connected
          to the Services;
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(c)</p>
        <p className="mt-3">
          obtain, or attempt to obtain, access to areas of the Services or our
          system that are not intended for access by you; “flood” the Services
          with requests to otherwise overburden, disrupt, or harm the Services
          or our systems;
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(d)</p>
        <p className="mt-3">
          restrict or inhibit other users from accessing or using the Services;
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(e)</p>
        <p className="mt-3">
          modify or delete any copyright, trademark or other proprietary rights
          notices that appear on the App or in the Content; or
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(f)</p>
        <p className="mt-3">
          access or use the Services or Content for any unlawful purpose or
          otherwise beyond the scope of the rights granted herein.
        </p>
      </div>
      <p className="text-lg font-bold mt-5">USER ACKNOWLEDGEMENT</p>
      <p className="mt-3">
        BBBN provides a technology platform connecting independent Family/Friend
        seeking release of a criminally accused, and independent Bonding
        Companies and wholesalers (collectively, “Users”) with one another. Any
        employment or independent contractor relationship entered into between
        or among Users is directly between and among the Users and BBBN, is not
        a party to any such relationship.
      </p>
      <p className="mt-3">
        You acknowledge and agree that the relationship between you and BBBN, is
        limited to the use of the current marketplace being provided by BBBN.
        Users are neither employees nor independent contractors of BBBN. No
        joint venture, franchisor-franchisee, partnership or agency relationship
        is intended or created by these Terms of Use.
      </p>
      <p className="mt-3">
        The Services offered by BBBN do not include those of a bail bonding
        company or business. BBBN, does not in any way employ, supervise,
        manage, direct, or control the work of Users in any way and does not
        assess, warrant, or guarantee the suitability,, skills, experience, or
        abilities of any User. BBBN does not verify Users’ compliance with
        applicable laws or regulations and has no responsibility or liability
        for the acts and/or omissions of any User. Accordingly, in the event you
        transact business with other Users through the Services, you do so at
        your own risk, and you agree to use your own independent skill,
        experience, knowledge and expertise in doing so, without reliance on
        BBBN or the Services for such purposes.
      </p>
      <p className="mt-3">
        While BBBN, makes available certain aspects of the Services to Users
        free of charge, BBBN, may also offer now or in the future a paid
        membership subscription fee which BBBN refers to as its Sub Subscription
        plan. Any Sub Subscription plan offers numerous benefits to Users above
        and beyond BBBN’s free Services offerings. In addition, the usage of any
        free or paid SubScription plan allows BBBN to leverage its database of
        potential Bonding Companies in order to send push notifications and/or
        emails to Users that match the posting’s requirements or criteria.
        Accordingly, you acknowledge and agree that BBBN may prioritize certain
        postings made by certain Users.
      </p>
      <p className="text-lg font-bold mt-5">OWNERSHIP</p>
      <p className="mt-3">
        The Services (Including the Content) are owned by BBBN and are protected
        under copyright, trademark, and other applicable United States and
        international laws and treaties without limiting the foregoing, the
        trademarks, service marks, and logos displayed on this site are
        registered and unregistered marks of BBBN, and its licensors. You
        acknowledge and agree that, as between you and BBBN, is and shall remain
        the sole owner of the Services and the Content, including without
        limitation, all patents, copyrights, trademarks, trade secrets, and
        other intellectual property and proprietary rights therein and thereto.
        Any User further acknowledges and agrees that all information collected
        by BBBN, is subject to our Privacy Policy by using the Services and you
        consent to all actions we take with respect to your information in
        compliance with our Privacy Notice.
      </p>
      <p className="text-lg font-bold mt-5">
        REGISTRATION AS A MEMBER, APPLICATION OF TERMS TO YOU; YOUR ACCOUNT AND
        SECURITY
      </p>
      <p className="mt-3">
        Access to and use of the Services require you to register for an
        account. You are responsible for maintaining the security of your user
        account and account information, and you are fully responsible for all
        activities that occur under the account and any other actions taken in
        connection with the account.
      </p>
      <p className="mt-3">
        You agree to provide true, accurate, current and complete account
        information, about yourself as prompted by the applicable registration
        or log-in form and are responsible for keeping such information up to
        date. You are solely responsible and liable for any account information
        submitted, as well as any other activity that takes place on or through
        your account regardless of who conducts those activities. Any
        information or content submitted on or through your account is deemed to
        have been submitted by you.
      </p>
      <p className="mt-3">
        You are responsible for maintaining your account login credentials
        confidential. You may not share your username or password to any other
        person or entity or otherwise permit anyone to access or use your
        account. You are responsible for maintaining the confidentiality of your
        account information, including your username and password. You must
        immediately notify BBBN of any unauthorized uses of your account or any
        other breaches of security. BBBN is not liable for any acts or omissions
        by You, including any damages or loss of any kind incurred as a result
        of such acts or omissions from your failure to protect your username or
        password.
      </p>
      <p className="text-lg font-bold mt-5">
        PAYMENT PROCESSING AND AUTOMATIC RENEWAL OF ACCOUNT
      </p>
      <p className="mt-3">
        BBBN may charge you a membership subscription fee in order to provide any
        accepted paid service described above.  You acknowledge and understand
        that any payment processing related to membership subscription fee is
        performed by either BBBN directly or by BBBN affiliate on behalf of BBBN
        depending on the type of payment method used. You also acknowledge that
        BBBN reserves the right, at any time, to modify its subscription fee and
        billing methods.
      </p>
      <p className="mt-3">
        Your paid membership subscription fee will automatically renew each
        month using the credit card, debt card or other payment information on
        file with BBBN or its authorized service provider.  Such renewal payment
        will take place on or about your renewal date.  Your membership
        subscription fee will continue, and your payment method will be charged,
        the membership subscription fee until you cancel, which you may do at
        any time. (see CANCELLING YOUR SUBSCRIPTION, below).
      </p>
      <p className="mt-3">
        By subscribing you agree to pay the membership subscription fee at the
        rates in effect when the charges were incurred.  All fees and charges
        are nonrefundable regardless of when you joined BBBN.
      </p>
      <p className="mt-3">
        You may cancel your membership subscription at any time by contacting
        BBBN within the mobile app and/or website.
      </p>
      <p className="text-lg font-bold mt-5">DECLINATION OF PAYMENT</p>
      <p className="mt-3">
        If an eligible payment method BBBN or its authorized service provider has
        on file for you is declined for payment, you must provide us with a new
        eligible payment method promptly or your membership subscription will
        canceled.  When successfully charged, your BBBN membership subscription
        period will be based on the original enrollment or renewal date and not
        the date of the successful charge.
      </p>
      <p className="text-lg font-bold mt-5">
        CHANGE AND SUSPENSION OF SERVICES
      </p>
      <p className="mt-3">
        BBBN reserves the right to make changes to, suspend, or discontinue
        (temporarily or permanently) the Services or any portion thereof
        (Including any Content) at any time. You agree that BBBN will not be
        liable to you or to any third party for any such change, suspension, or
        discontinuance. BBBN also has the right to deny access to, and to suspend
        or terminate your access to, the Services or to any features or portions
        thereof if you violate these Terms of Use. If we suspect or terminate
        your access to Services, you will continue to be bound by the Terms of
        Use that were in effect as of the date of your suspension or
        termination.
      </p>
      <p className="text-lg font-bold mt-5">USER GENERATED CONTENT</p>
      <p className="mt-3">
        You acknowledge and agree all content and information posted by you on
        BBBN including but not limited to photographs, images, graphics, designs,
        profiles, messages, information, notes, text, videos and all means of
        materials and content of any type may be used by BBBN within its platform
        only for purposes of servicing Users. We do not claim ownership over any
        User Generated Content (“UGC”) and by providing any UGC, you
      </p>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(a)</p>
        <p className="mt-3">
          Acknowledge and agree that BBBN reserves the right to refuse to a
          accept, display, or transmit any UGC in its sole discretion;
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(b)</p>
        <p className="mt-3">
          Further, to the extent permitted under applicable law, you waive and
          release and convent not to assert any moral rights that you may have
          in any UGC posted or provide by you.
        </p>
      </div>
      <p className="text-lg font-bold mt-5">
        REPRESENTATION OF OWNERSHIP AND RIGHT TO USE USER GENERATED CONTENT
      </p>
      <p className="mt-3">
        By posting or providing any UGC to BBBN, you represent and warrant to BBBN
        that you own or have all necessary rights to use the UGC, and grant to
        BBBN the rights granted below. The forgoing representation includes, but
        is not limited to a representation and warranty that you own or have the
        necessary rights (including any necessary releases) to grant all rights
        granted below in relation to any persons, places or intellectual
        property pictured in any photographic UGC that you provide. In addition,
        if you post or otherwise provide any UGC that is protected by copyright,
        you represent that you have obtained any necessary permissions or
        releases from the applicable copyright owner.
      </p>
      <p className="text-lg font-bold mt-5">
        USER GENERATED CONTENT GUIDELINES
      </p>
      <p className="mt-3">
        You acknowledge and agree that (a) you are solely responsible for all of
        your UGC, and (b) we may, but are not obligated to, review and delete or
        remove (without notice) any UGC, in our sole discretion, including,
        without limitation, any UGC that violates this Agreement. You agree that
        you must evaluate, and bear all risks associated with, the use of any
        UGC, including any reliance on the accuracy, completeness, or usefulness
        of such Content. In this regard, you acknowledge that you may not rely
        on any Service Content (except for this Agreement, the Privacy Policy
        and other legally binding agreements) for any purpose whatsoever. You
        will also comply with any of your own local laws regarding online
        conduct and acceptable content, including laws relating to the export of
        data and agree to:
      </p>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(a)</p>
        <p className="mt-3">
          UGC is not obscene, indecent, does not defame, disparage, invade the
          privacy of, or infringe upon or violate any rights of any kind
          whatsoever of any individual or entity;
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(b)</p>
        <p className="mt-3">
          UGC does not violate any law, rule, or regulation of any governmental
          entity;
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(c)</p>
        <p className="mt-3">
          UGC is not subject to any obligation of confidentiality;
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(d)</p>
        <p className="mt-3">
          UGC not contain any fraudulent statements or misrepresentations that
          could damage BBBN or any third party; and
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(e)</p>
        <p className="mt-3">
          You have not received any compensation of any kind for any UGC, unless
          properly disclosed pursuant to FTC guidelines.
        </p>
      </div>
      <div className="mt-3 flex flex-row pl-4">
        <p className="mt-3 mr-2">(f)</p>
        <p className="mt-3">
          You hereby release, discharge and agree to hold us, and any person
          acting on their behalf, harmless from any liability related in any way
          to our use of your UGC.
        </p>
      </div>
      <p className="mt-3">
        The UGC that you submit is deemed non-confidential and we have no
        obligation to maintain the confidentiality of any information, in
        whatever form, contained in any submission, except pursuant to our
        respective privacy policies. By using BBBN or the Services, you are
        consenting to our collection of any personal information you provide for
        our use and disclosure in connection with the use of your UGC as
        described herein. If you do not agree to the collection, use and
        disclosure of your personal information in this way, please do not use
        BBBN or the Services or otherwise provide us with personal information.
      </p>
      <p className="mt-3">
        Your personal information may be transferred to servers located outside
        the country in which you live or to third parties in other countries so
        that they may process personal information on our behalf. By using BBBN
        or the Services or otherwise providing the Licensed Parties with
        personal information, you agree to the foregoing collection, use,
        disclosure, transfer and processing of your information in accordance
        with the terms of these Terms of Use , the Privacy Policy and applicable
        data protection laws and regulations.
      </p>
      <p className="mt-3">
        You understand that, under no circumstances will we be liable in any way
        for any UGC, including, without limitation, for any errors or omissions
        in any UGC, or for any loss or damage of any kind incurred by you as a
        result of the use of any UGC transmitted, uploaded, posted, e-mailed or
        otherwise made available via the Site.
      </p>
      <p className="mt-3">
        You also agree that we are under no obligation to use any UGC. You
        acknowledge that we reserve the right to change, condense, edit, modify,
        transmit, display, or delete any UGC, and you waive any rights you have
        in having the material altered or changed posting, e-mailing or
        otherwise making available on the Site or other social media sites.
      </p>
      <p className="text-lg font-bold mt-5">
        COPYRIGHT AND TRADEMARK TAKEDOWN REQUEST
      </p>
      <p className="mt-3">
        If you believe that your copyright or trademark is being infringed on
        the BBBN website and/or mobile application, the fastest and easiest way
        to request a takedown of a copyright infringement (pursuant to the
        Digital Millennium Copyright Act of 1998 (“DMCA”) or a trademark
        infringement is to contact BailBondBidsNow.com at&nbsp;
        <a
          className="text-blue-500 underline"
          href="mailto:richard@bailbondbidsnow.com"
        >
          richard@bailbondbidsnow.com
        </a>
      </p>
      <p className="mt-3">
        The notice must include the following information as required by 17 USC.
        § 512(c)(3)(A). If you believe in good faith that a notice of copyright
        infringement has been wrongly filed against you, the DMCA permits you to
        send BBBN a counter-notice. Notices and counter-notices must meet the
        then-current statutory requirements imposed by the DMCA; see 
        <a
          className="text-blue-500 underline"
          href="http://www.loc.gov/copyright/"
        >
          http://www.loc.gov/copyright/
        </a>
        &nbsp; for details. We suggest that you consult your legal advisor
        before filing a notice or counter-notice. Also, be aware that there are
        penalties for false claims under the DMCA.
      </p>
      <p className="text-lg font-bold mt-5">REPEAT INFRINGER POLICY</p>
      <p className="mt-3">
        By using BBBN, you have agreed to our Terms of Use. Our Terms prohibit
        people from taking any action on BBBN that infringes or violates someone
        else's intellectual property rights or otherwise violates the law. If
        you repeatedly transmit or post content that infringes someone else’s
        intellectual property rights, such as copyrights or trademarks,  BBBN may
        terminate your use of our services; limit your ability to use our
        services; and/or take any other action BBBN believes necessary to end the
        infringement and ensure that it does not reoccur.  The actions taken
        under this policy may depend on the particular circumstances of the
        matter, such as the nature of the violation, the particular content
        infringed, what aspect of our services were used to commit the
        violation, and the results of any appeals process that we possibly
        provide.
      </p>
      <p className="text-lg font-bold mt-5">MOBILE APPLICATION PHONE USE</p>
      <p className="mt-3">
        You agree to adhere to any terms of any app store, mobile software
        platform, payment platform, or other third party services provided in
        connection with your use of the BBBN mobile application. We reserve all
        rights in and to the BBBN mobile application not expressly granted to you
        under this Agreement and you only have a limited license to use it to
        access the Service in accordance with these terms. By providing your
        mobile phone number and using the Service, you hereby affirmatively
        consent to our use of your mobile phone number for calls and texts from
        us and certain other Users relating to Requests relevant to you, such as
        those you submit. You understand and acknowledge that by communicating
        by calls or text, standard message charges or other charges from your
        wireless carrier may apply. You may opt-out of receiving text messages
        from us by modifying your User Account settings on the Website or App or
        by emailing &nbsp;
        <a
          className="text-blue-500 underline"
          href="mailto:richard@bailbondbidsnow.com"
        >
          richard@bailbondbidsnow.com
        </a>
      </p>
      <p className="text-lg font-bold mt-5">ELECTRONIC COMMUNICATIONS</p>
      <p className="mt-3">
        The communications between you and BBBN via the services use electronic
        means. For contractual purposes, you consent to receive communications
        from us in electronic form, and you agree that all terms and conditions,
        agreements, notices, disclosures, and other communications that we
        provide to you electronically satisfy any legal requirement that such
        communications be in writing.
      </p>
      <p className="text-lg font-bold mt-5">DISCLAIMER OF WARRANTIES</p>
      <p className="mt-3">
        The Services and the Content are provided to you on an “as is” and “as
        available” basis, without warranties of any kind, and BBBN hereby
        disclaims all warranties, whether express, implied, statutory, or
        otherwise, including but not limited to any implied warranties of
        merchantability, fitness for a particular purpose, and non-infringement.
        Neither BBBN nor any person associated with BBBN makes any representation
        or warranty with respect to the completeness, security, reliability,
        quality, accuracy, or availability of the Services or the Content.
        Without limiting the foregoing, neither BBBN nor anyone associated with
        BBBN represents or warrants that the Services or the Content will be
        accurate, reliable, error-free, or uninterrupted, that defects will be
        corrected, that the Services, the Content, or the servers that make them
        available are free of viruses or other harmful components, or that the
        Services or the Content will otherwise meet your needs or expectations.
      </p>
      <p className="text-lg font-bold mt-5">LIMITATION OF LIABILITY</p>
      <p className="mt-3">
        To the fullest extent permitted by law, in no event will BBBN or its
        officers, directors, employees, agents, licensors, or service providers
        be liable for any direct, indirect, incidental, consequential, special,
        punitive, or exemplary damages arising from or related to your use of or
        inability to use the Services or Content, including but not limited to,
        personal injury or death, loss of revenue, loss of profits, loss of
        business, loss of use, or loss of data, whether caused by tort
        (including negligence), breach of contract, or otherwise, even if
        foreseeable. If, notwithstanding the provisions of this section, BBBN is
        found liable for any loss, damage, or injury under any legal theory
        relating in any way to the subject matter of these Terms of Use, in no
        event will BBBN’s total liability to you or any third party exceed the
        total amount of fees, if any paid by you to BBBN in the twelve month
        period preceding the event giving rise to the claim or U.S. $100,
        whichever is less. The foregoing limitations shall apply even if your
        remedies under these Terms of Use fail their essential purpose. Use of
        the Services are at your own risk.
      </p>
      <p className="text-lg font-bold mt-5">INDEMNIFICATION</p>
      <p className="mt-3">
        You agree to indemnify, defend, and hold BBBN and its officers,
        directors, employees, agents, licensors, and service providers harmless
        from and against any claims, liabilities, losses, damages, judgments,
        awards, costs, and expenses (including reasonable attorneys’ fees)
        arising out of or resulting from your use of the Services and/or any
        Content, or any violation of these Terms of Use or applicable law. We
        reserve the right, at our own expense, to assume the exclusive defense
        and control of any action subject to indemnification by you, and in such
        event, you agree to cooperate with us in defending such action. Your
        indemnification, defense, and hold harmless obligations will survive the
        termination of your use of the Services and/or these Terms of Use.
      </p>
      <p className="text-lg font-bold mt-5">SEVERABILITY</p>
      <p className="mt-3">
        These Terms of Use incorporate by reference any notices contained on the
        website and/or mobile application, the Privacy Notice, or Copyright and
        Trademark information, and constitute the entire agreement with respect
        to access to and use of this website and mobile application. In the
        event there is a conflict amongst these Terms of Use is unlawful, the
        remaining provisions and shall not affect their validity and
        enforceability.
      </p>
      <p className="text-lg font-bold mt-5">THIRD-PARTY MATERIALS</p>
      <p className="mt-3">
        The Services may display, include, or make available third-party content
        (including data, information, applications and other products service
        and/or materials) or provide links to third-party websites or services
        (collectively, “Third-Party Materials”). You acknowledge and agree that
        BBBN is not responsible for any Third-Party Materials. Third-Party
        Materials and links thereto are provided solely as a convenience to you,
        and you access and use them entirely at your own risk and subject to
        such third parties’ terms and conditions.
      </p>
      <p className="text-lg font-bold mt-5">THIRD-PARTY PLATFORMS</p>
      <p className="mt-3">
        BBBN may provide the Services to you through third-party websites,
        operating systems, platforms, and portals (collectively, “Third-Party
        Platforms”) Additional terms and conditions may apply to you with
        respect to your use of Third-Party Platforms, which are not under BBBN’s
        control. BBBN does not assume any responsibility or liability for your
        use of such Third-Party Platforms.
      </p>
      <p className="text-lg font-bold mt-5">
        GOVERNING LAW; JURISDICTION AND VENUE
      </p>
      <p className="mt-3">
        These Terms of Use and any dispute or claim arising out of or related to
        these Terms of Use, their subject matter, or their formation shall be
        governed by and construed in accordance with the laws of the State of
        Texas, other than such laws and case law that would result in the
        application of the laws of jurisdiction other than the State of Texas.
      </p>
      <p className="text-lg font-bold mt-5">QUESTIONS AND COMPLAINT</p>
      <p className="mt-3">
        If you have any questions about our privacy practices, these Terms of
        Use, would like to report unethical behavior, or if you would like to
        make a complaint, please contact us at&nbsp;
        <a
          className="text-blue-500 underline"
          href="mailto:richard@bailbondbidsnow.com"
        >
          richard@bailbondbidsnow.com
        </a>
        &nbsp;or on the website or mobile application Contact US sections.
      </p>
    </div>
  );
};

export default ToS;
