import React from "react";
import Navbar from "../components/Navbar";
import { useHistory } from "react-router-dom";

const OfferSentPage = () => {
  const history = useHistory();

  const onClose = (e) => {
    e.preventDefault();
    history.go(-2);
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4 text-2xl font-bold">
        <p className="mt-4">Congratulations,</p>
        <p className="mt-8 text-center">
          Your message has been sent to the selected Bondsman. They will be contacting you shortly. BailBondBidsNow.com
        </p>
        <button
          className={`w-48 bg-primary text-white py-2 mt-8 rounded-full focus:outline-none`}
          onClick={(event) => onClose(event)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default OfferSentPage;
