import React from "react";
import { AuthProvider } from "./providers/Authentication";
import PublicRoute from "./routes/PublicRoute";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(
  "pk_test_51L7gW3IBGLX9a8upCVNREjaiP4jVqPB9c9cVKo1o1tz2lEOh0LvoY9CfPx3I39uafD9obqFWAk6s7n3GGRgLPh4S0065nhXrzp"
);

function App() {
  return (
    <Elements stripe={stripePromise}>
      <AuthProvider>
        <PublicRoute />
      </AuthProvider>
    </Elements>
  );
}

export default App;
