import React, { useContext, useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { AuthContext } from "../providers/Authentication";
import { database } from "../firebase";
import avatar from "../assets/image/avatar.png";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { ThreeBounce } from "better-react-spinkit";

const MyJobsPage = () => {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const [jobs, setJobs] = useState([]);
  const [renderedJobs, setRenderedJobs] = useState([]);
  const [indicator, setIndicator] = useState(false);
  const [offset, setOffset] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    async function fetchData() {
      if (currentUser && currentUser.userType === 1) {
        setIndicator(true);
        const sEmail = currentUser.Email;
        let tempJobs = [];
        let updatedJobs = [];
        await database.ref("JT").once("value", (snapshot) => {
          if (!!snapshot.val()) {
            snapshot.forEach((child) => {
              if (!child.val().isHidden) tempJobs.push(child.val());
            });
          }
        });

        tempJobs.sort((a,b) => (a.RequestedAt < b.RequestedAt) ? 1 : ((b.RequestedAt < a.RequestedAt) ? -1 : 0));
        let item = {};
        for (item of tempJobs) {
          let bidAvailability = false;
          let tempUserId = item.UserUid;
          const snapshot1 = await database
            .ref(`FFSRT/${tempUserId}`)
            .once("value");
          const icon = snapshot1.val().IconUpload;
          const gBirth = snapshot1.val().Arrestee.Birthday;
          const gFacility = snapshot1.val().Arrestee.Facility;
          const gName = snapshot1.val().Arrestee.Name;
          const gPhone = snapshot1.val().FFSR.Phone;
          let tempJobId = item.JobId;
          await database
            .ref("BT")
            .once("value", (snapshot3) => {
              if (!!snapshot3.val()) {
                snapshot3.forEach((child) => {
                  if (
                    tempJobId === child.val().JobId &&
                    currentUser.UserUid === child.val().UserUid
                  ) {
                    bidAvailability = true;
                  }
                });
              }
            })
            // eslint-disable-next-line
            .then(() => {
              const newItem = {
                ...item,
                icon,
                sEmail,
                bidAvailability,
                gName,
                gBirth,
                gPhone,
                gFacility,
              };
              updatedJobs.push(newItem);
            });
        }

        let filteredUpdatedJobs = [];

        if (currentUser.Counter > 0 ) {
          filteredUpdatedJobs = [...updatedJobs];
        } else {
          for (let county of currentUser.SelectedCounties || []) {
            filteredUpdatedJobs = [...filteredUpdatedJobs, ...(updatedJobs.filter(job => job.gFacility === county))]; 
          }
        }
        
        setJobs(filteredUpdatedJobs);
        setPageCount(Math.ceil(filteredUpdatedJobs.length / 5));
        setIndicator(false);
      } else if (currentUser && currentUser.userType === 0) {
        setIndicator(true);
        let tempBids = [];
        let updatedBids = [];
        const gEmail = currentUser.Email;
        const gPhone = currentUser.Phone;
        await database.ref("BT").once("value", (snapshot) => {
          if (!!snapshot.val()) {
            snapshot.forEach((child) => {
              if (currentUser.UserUid === child.val().CreatorId) {
                tempBids.push(child.val());
              }
            });
          }
        });

        for (let item of tempBids) {
          let hireAvailability = false;
          let tempUserId = item.UserUid;
          const snapshot1 = await database
            .ref(`BCT/${tempUserId}`)
            .once("value");
          if (!!snapshot1.val()) {
            const icon = snapshot1.val().IconUpload;
            const sEmail = snapshot1.val().Email; 
            const sName = snapshot1.val().Name;
            const sPhone = snapshot1.val().Phone;
            const sUid = item.UserUid;
            const newItem = {
              ...item,
              icon,
              sEmail,
              gEmail,
              hireAvailability,
              gPhone,
              sPhone,
              sName,
              sUid,
            };
            updatedBids.push(newItem);
          }
        }

        setJobs(updatedBids);
        setPageCount(Math.ceil(updatedBids.length / 5));
        setIndicator(false);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setRenderedJobs(
      jobs.filter(
        (job, index) => index >= (offset - 1) * 5 && index < offset * 5
      )
    );
  }, [jobs, offset]);

  const onPressView = (e, job) => {
    e.preventDefault();
    history.push({
      pathname: "viewprofile",
      state: { ffUserUid: job.UserUid, bidAvailability: job.bidAvailability },
    });
  };

  const onPressBid = (e, job) => {
    e.preventDefault();
    history.push({
      pathname: "bid",
      state: { job },
    });
  };

  const onPressBids = (e, job) => {
    e.preventDefault();
    history.push({
      pathname: "currentbids",
      state: { job },
    });
  };

  const handlePageClick = (data) => {
    setOffset(data.selected + 1);
  };

  if (currentUser.userType === 0) {
    return (
      <div className="w-full">
        <Navbar />
        {indicator ? (
          <div className="flex justify-center items-center flex-col my-80">
            <ThreeBounce size={24} color="#03a9f4" />
            Please wait...
          </div>
        ) : (
          <div className="flex flex-col items-center py-4">
            {renderedJobs.length === 0 ? (
              <p>Any Incoming Bids will Appear Momentarily.</p>
            ) : (
              renderedJobs.map((job) => (
                <div
                  className="w-96 md:w-max border px-4 py-2 my-2"
                  key={job.JobId}
                >
                  <div className="flex items-center">
                    <img
                      src={
                        currentUser.IconUpload ? currentUser.IconUpload : avatar
                      }
                      alt="..."
                      className="w-16 h-16 rounded-full"
                    />
                    <div className="ml-4 flex-1">
                      <p>Arrestee's Name: {currentUser.Arrestee.Name}</p>
                      <p>Bonding Company Name: {job.sName}</p>
                      <p>Bonding Company Total Bond Price: {job.TotalBondPrice}</p>
                    </div>
                  </div>
                  <div className="flex items-center justify-evenly">
                    <button
                      className={`w-36 ${
                        job.checkBids ? "bg-green-500" : "bg-primary"
                      } text-white py-2 my-3 ml-3 rounded-full focus:outline-none`}
                      onClick={(event) => onPressBids(event, job)}
                    >
                      Incoming Bid
                    </button>
                  </div>
                </div>
              ))
            )}
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"flex justify-center my-4 items-center"}
              activeClassName={"primary font-bold font-lg"}
              pageClassName={"px-2 text-xl border"}
              previousClassName={"border px-3 primary mr-2"}
              nextClassName={"border px-3 primary ml-2"}
            />
          </div>
        )}
      </div>
    );
  }

  if (currentUser.userType === 1 && currentUser.Counter <= 0 && !currentUser.Subscription) {
    return (
      <div className="w-full">
        <Navbar />
        <div className="flex flex-col items-center py-4 text-2xl font-bold text-center px-12 mt-12">
          All 12 Free Bids have been used. Please subscribe under the "Company Profile" to continue access to incoming requested Bids.
        </div>
      </div>
    )
  }

  return (
    <div className="w-full">
      <Navbar />
      {indicator ? (
        <div className="flex justify-center items-center flex-col my-80">
          <ThreeBounce size={24} color="#03a9f4" />
          Please wait...
        </div>
      ) : (
        <div className="flex flex-col items-center py-4">
          {renderedJobs.length === 0 ? (
            <p>Any Posted Jobs will Appear Momentarily.</p>
          ) : (
            renderedJobs.map((job) => (
              <div className="border px-4 py-2 my-2" key={job.JobId}>
                <div className="flex items-center">
                  <img
                    src={job.icon ? job.icon : avatar}
                    alt="..."
                    className="w-16 h-16 rounded-full"
                  />
                  <div className="ml-4 flex-1">
                    <p>Arrestee's Name: {job.gName}</p>
                    <p>Arrestee's Date of Birth: {job.gBirth}</p>
                    <p>
                      Arrestee's Current Jail / Detention Facility:{" "}
                      {job.gFacility}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-evenly">
                  <button
                    className={`w-48 bg-primary text-white py-2 my-3 rounded-full focus:outline-none text-sm`}
                    onClick={(event) => onPressView(event, job)}
                  >
                    View Arrestee & Family/Friend's Profiles
                  </button>
                  <button
                    className={`w-48 ${
                      job.bidAvailability ? "bg-gray-500" : "bg-primary"
                    } text-white py-2 my-3 rounded-full focus:outline-none`}
                    onClick={(event) => onPressBid(event, job)}
                    disabled={job.bidAvailability}
                  >
                    Submit Bid for Bail
                  </button>
                </div>
              </div>
            ))
          )}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"flex justify-center my-4 items-center"}
            activeClassName={"primary font-bold font-lg"}
            pageClassName={"px-2 text-xl border"}
            previousClassName={"border px-3 primary mr-2"}
            nextClassName={"border px-3 primary ml-2"}
          />
        </div>
      )}
    </div>
  );
};

export default MyJobsPage;
