import React from "react";
import Navbar from "../components/Navbar";
import { useHistory } from "react-router-dom";

const PostSuccessPage = () => {
  const history = useHistory();

  const onClose = (e) => {
    e.preventDefault();
    history.push("/myjobs");
  };

  return (
    <div className="w-full">
      <Navbar />
      <div className="flex flex-col items-center py-4 text-2xl font-bold">
        <p className="mt-4">Congratulations,</p>
        <p className="mt-8 text-center px-8">
          Thank you for submitting your request for Bids. You will receive emails and/or phone notifications when competitive incoming Bids arrive.
          Upon return please view and compare the incoming Bids under the above "Incoming Bids for Bail" section."
        </p>
        <button
          className={`w-48 bg-primary text-white py-2 mt-8 rounded-full focus:outline-none`}
          onClick={(event) => onClose(event)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default PostSuccessPage;
