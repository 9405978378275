import React, { useContext, useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../providers/Authentication";
import { database } from "../firebase";
import avatar from "../assets/image/avatar.png";
import { ThreeBounce } from "better-react-spinkit";

const SelectedBidsPage = () => {
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const [indicator, setIndicator] = useState(false);
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setIndicator(true);
      let tempOffers = [];
      let updateOffers = [];
      await database.ref("OT").once("value", (snapshot) => {
        if (!!snapshot.val()) {
          snapshot.forEach((child) => {
            if (
              child.val().SCUid === currentUser.UserUid &&
              child.val().isReplied === false
            )
              tempOffers.push(child.val());
          });
        }
      });

      let item = {};
      for (item of tempOffers) {
        const gUserId = item.UserUid;
        const snapshot1 = await database.ref(`FFSRT/${gUserId}`).once("value");
        if (!!snapshot1.val()) {
          const gName = snapshot1.val().FFSR.Name;
          const gEmail = snapshot1.val().FFSR.Email;
          const gIcon = snapshot1.val().IconUpload;
          const gPhone = snapshot1.val().FFSR.Phone;
          const newItem = { ...item, gName, gEmail, gIcon, gPhone };
          updateOffers.push(newItem);
        }
      }
      setOffers(updateOffers);
      setIndicator(false);
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  const onPressView = (e, offer) => {
    e.preventDefault();
    history.push({
      pathname: "viewprofile",
      state: { ffUserUid: offer.UserUid, bidAvailability: true },
    });
  };

  return (
    <div className="w-full">
      <Navbar />
      {indicator ? (
        <div className="flex justify-center items-center flex-col my-80">
          <ThreeBounce size={24} color="#03a9f4" />
          Please wait...
        </div>
      ) : (
        <div className="flex flex-col items-center py-4">
          {offers.length === 0 ? (
            <p>Any Selected Bids will appear momentarily.</p>
          ) : (
            offers.map((offer) => (
              <div className="border px-4 py-2 my-2" key={offer.OfferId}>
                <div className="flex items-center">
                  <img
                    src={offer.icon ? offer.icon : avatar}
                    alt="..."
                    className="w-16 h-16 rounded-full"
                  />
                  <div className="ml-4">
                    <p>Family/Friend's Name: {offer.gName}</p>
                    <p>Family/Friend's Phone: {offer.gPhone}</p>
                    <p>Family/Friend's Email: {offer.gEmail}</p>
                  </div>
                </div>
                <div className="flex items-center justify-evenly">
                  <button
                    className={`w-48 bg-primary text-white py-2 my-3 rounded-full focus:outline-none text-sm`}
                    onClick={(event) => onPressView(event, offer)}
                  >
                    View Arrestee & Family/Friend's Profiles
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default SelectedBidsPage;
