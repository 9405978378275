import React, { useContext, useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import avatar from "../assets/image/avatar.png";
import { AuthContext } from "../providers/Authentication";
import { database, storage } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import ToS from "../components/ToS";
import InputWrapper from "../components/InputWrapper";
import { useHistory } from "react-router-dom";
import reviewAndSubmit from "../assets/image/review_and_submit.png";
import gcAndSc from "../assets/image/gc_and_sc.png";
import jobCompleted from "../assets/image/job_completed.png";
import rightArrow from "../assets/image/right_arrow.png";
import axios from "axios";

const DATA = [
  " ",
  "Louisiana - Orleans Parish",
  "Oklahoma - Cleveland County",
  "Oklahoma - Oklahoma County",
  "Oklahoma - Tulsa County",
  "Texas - Bexar County",
  "Texas - Collin County",
  "Texas - Comal County",
  "Texas - Dallas County",
  "Texas - Denton County",
  "Texas - Harris County",
  "Texas - Montgomery County",
  "Texas - Tarrant County",
  "Texas - Travis County",
  "Texas - Williamson County",
];

const ProfilePage = () => {
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const [name, setName] = useState(currentUser.Name);
  const [licenseNumber, setLicenseNumber] = useState(currentUser.LicenseNumber);
  const [dba, setDBA] = useState(currentUser.DBA);
  const [email, setEmail] = useState(currentUser.Email);
  const [phone, setPhone] = useState(currentUser.Phone);
  const [address, setAddress] = useState(currentUser.Address);
  const [location, setLocation] = useState(currentUser.Location || DATA[0]);
  const [accepted, setAccepted] = useState(
    currentUser.TermsAndPoliciesCheckBox || false
  );
  const [icon, setIcon] = useState(currentUser.IconUpload);
  const [error, setError] = useState(null);
  const [isSame, setIsSame] = useState(currentUser.IsSame || false);
  const [ffsr, setFFSR] = useState(
    currentUser.FFSR || {
      Name: "",
      Phone: "",
      Email: "",
      Birthday: "",
      Address: "",
      ID: "",
      Relationship: "",
      EmployeerName: "",
      EmployeerPhone: "",
      EmployeerHowLong: "",
      IsAvailable: false,
      Collateral: "",
      AlternateName: "",
      AlternatePhone: "",
      AlternateEmail: "",
    }
  );
  const [bondPromisor, setBondPromisor] = useState(
    currentUser.BondPromisor || {
      Name: "",
      Phone: "",
      Email: "",
      Birthday: "",
      Address: "",
      ID: "",
      Relationship: "",
      EmployeerName: "",
      EmployeerPhone: "",
      EmployeerHowLong: "",
      IsAvailable: false,
      Collateral: "",
      AlternateName: "",
      AlternatePhone: "",
      AlternateEmail: "",
    }
  );
  const [arrestee, setArrestee] = useState(
    currentUser.Arrestee || {
      Name: "",
      Phone: "",
      Email: "",
      Birthday: "",
      Address: "",
      Citizenship: "",
      ImmigrationStatus: "",
      SSN: "",
      ID: "",
      Relationship: "",
      EmployeerName: "",
      EmployeerPhone: "",
      EmployeerHowLong: "",
      SID: "",
      Agency: "",
      Facility: "",
      PriorArrests: "",
      CriminalHistory: "",
      PriorPeriods: "",
      AdditionalName1: "",
      AdditionalName2: "",
      AdditionalPhone1: "",
      AdditionalPhone2: "",
      AdditionalRelationship1: "",
      AdditionalRelationship2: "",
      AdditionalEmail1: "",
      AdditionalEmail2: "",
    }
  );
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (isSame) {
      setBondPromisor(ffsr);
    }
    // eslint-disable-next-line
  }, [isSame]);

  const onSelect = (value) => {
    setLocation(value.value);
  };

  const onSelectFacility = (value) => {
    setArrestee({
      ...arrestee,
      Facility: value.value,
    });
  };

  const handleUpload = () => {
    const fileInputElement = fileInputRef.current;
    fileInputElement.click();
  };

  const handleChange = async (e) => {
    if (e.currentTarget.files !== null) {
      const fileUploaded = e.currentTarget.files[0];
      if (currentUser.userType === 0) {
        await storage
          .ref("FFSRT")
          .child(`${currentUser.UserUid}/avatar`)
          .put(fileUploaded);
        const url = await storage
          .ref("FFSRT")
          .child(`${currentUser.UserUid}/avatar`)
          .getDownloadURL();
        setIcon(url);
        const gcUserRef = database.ref(`FFSRT/${currentUser.UserUid}`);
        await gcUserRef.update({
          IconUpload: url,
        });
        toast("Profile Avatar Saved!");
      } else if (currentUser.userType === 1) {
        await storage
          .ref("BCT")
          .child(`${currentUser.UserUid}/avatar`)
          .put(fileUploaded);
        const url = await storage
          .ref("BCT")
          .child(`${currentUser.UserUid}/avatar`)
          .getDownloadURL();
        setIcon(url);
        const scUserRef = database.ref(`BCT/${currentUser.UserUid}`);
        await scUserRef.update({
          IconUpload: url,
        });
        toast("Profile Avatar Saved!");
      }
    }
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "name") {
      setName(value);
    } else if (name === "licenseNumber") {
      setLicenseNumber(value);
    } else if (name === "dba") {
      setDBA(value);
    } else if (name === "address") {
      setAddress(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "ffsrName") {
      setFFSR({
        ...ffsr,
        Name: value,
      });
    } else if (name === "ffsrPhone") {
      setFFSR({
        ...ffsr,
        Phone: value,
      });
    } else if (name === "ffsrEmail") {
      setFFSR({
        ...ffsr,
        Email: value,
      });
    } else if (name === "ffsrBirthday") {
      setFFSR({
        ...ffsr,
        Birthday: value,
      });
    } else if (name === "ffsrAddress") {
      setFFSR({
        ...ffsr,
        Address: value,
      });
    } else if (name === "ffsrID") {
      setFFSR({
        ...ffsr,
        ID: value,
      });
    } else if (name === "ffsrRelationship") {
      setFFSR({
        ...ffsr,
        Relationship: value,
      });
    } else if (name === "ffsrEmployeerName") {
      setFFSR({
        ...ffsr,
        EmployeerName: value,
      });
    } else if (name === "ffsrEmployeerPhone") {
      setFFSR({
        ...ffsr,
        EmployeerPhone: value,
      });
    } else if (name === "ffsrEmployeerHowLong") {
      setFFSR({
        ...ffsr,
        EmployeerHowLong: value,
      });
    } else if (name === "ffsrCollateral") {
      setFFSR({
        ...ffsr,
        Collateral: value,
      });
    } else if (name === "ffsrAlternateName") {
      setFFSR({
        ...ffsr,
        AlternateName: value,
      });
    } else if (name === "ffsrAlternatePhone") {
      setFFSR({
        ...ffsr,
        AlternatePhone: value,
      });
    } else if (name === "ffsrAlternateEmail") {
      setFFSR({
        ...ffsr,
        AlternateEmail: value,
      });
    }
  };

  const onPromisorChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "promisorName") {
      setBondPromisor({
        ...bondPromisor,
        Name: value,
      });
    } else if (name === "promisorPhone") {
      setBondPromisor({
        ...bondPromisor,
        Phone: value,
      });
    } else if (name === "promisorEmail") {
      setBondPromisor({
        ...bondPromisor,
        Email: value,
      });
    } else if (name === "promisorBirthday") {
      setBondPromisor({
        ...bondPromisor,
        Birthday: value,
      });
    } else if (name === "promisorAddress") {
      setBondPromisor({
        ...bondPromisor,
        Address: value,
      });
    } else if (name === "promisorID") {
      setBondPromisor({
        ...bondPromisor,
        ID: value,
      });
    } else if (name === "promisorRelationship") {
      setBondPromisor({
        ...bondPromisor,
        Relationship: value,
      });
    } else if (name === "promisorEmployeerName") {
      setBondPromisor({
        ...bondPromisor,
        EmployeerName: value,
      });
    } else if (name === "promisorEmployeerPhone") {
      setBondPromisor({
        ...bondPromisor,
        EmployeerPhone: value,
      });
    } else if (name === "promisorEmployeerHowLong") {
      setBondPromisor({
        ...bondPromisor,
        EmployeerHowLong: value,
      });
    } else if (name === "promisorCollateral") {
      setBondPromisor({
        ...bondPromisor,
        Collateral: value,
      });
    } else if (name === "promisorAlternateName") {
      setBondPromisor({
        ...bondPromisor,
        AlternateName: value,
      });
    } else if (name === "promisorAlternatePhone") {
      setBondPromisor({
        ...bondPromisor,
        AlternatePhone: value,
      });
    } else if (name === "promisorAlternateEmail") {
      setBondPromisor({
        ...bondPromisor,
        AlternateEmail: value,
      });
    }
  };

  const onArresteeChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "arresteeName") {
      setArrestee({
        ...arrestee,
        Name: value,
      });
    } else if (name === "arresteePhone") {
      setArrestee({
        ...arrestee,
        Phone: value,
      });
    } else if (name === "arresteeEmail") {
      setArrestee({
        ...arrestee,
        Email: value,
      });
    } else if (name === "arresteeBirthday") {
      setArrestee({
        ...arrestee,
        Birthday: value,
      });
    } else if (name === "arresteeAddress") {
      setArrestee({
        ...arrestee,
        Address: value,
      });
    } else if (name === "arresteeCitizenship") {
      setArrestee({
        ...arrestee,
        Citizenship: value,
      });
    } else if (name === "arresteeImmigrationStatus") {
      setArrestee({
        ...arrestee,
        ImmigrationStatus: value,
      });
    } else if (name === "arresteeSSN") {
      setArrestee({
        ...arrestee,
        SSN: value,
      });
    } else if (name === "arresteeID") {
      setArrestee({
        ...arrestee,
        ID: value,
      });
    } else if (name === "arresteeRelationship") {
      setArrestee({
        ...arrestee,
        Relationship: value,
      });
    } else if (name === "arresteeEmployeerName") {
      setArrestee({
        ...arrestee,
        EmployeerName: value,
      });
    } else if (name === "arresteeEmployeerPhone") {
      setArrestee({
        ...arrestee,
        EmployeerPhone: value,
      });
    } else if (name === "arresteeEmployeerHowLong") {
      setArrestee({
        ...arrestee,
        EmployeerHowLong: value,
      });
    } else if (name === "arresteeSID") {
      setArrestee({
        ...arrestee,
        SID: value,
      });
    } else if (name === "arresteeAgency") {
      setArrestee({
        ...arrestee,
        Agency: value,
      });
    } else if (name === "arresteePriorArrests") {
      setArrestee({
        ...arrestee,
        PriorArrests: value,
      });
    } else if (name === "arresteeCriminalHistory") {
      setArrestee({
        ...arrestee,
        CriminalHistory: value,
      });
    } else if (name === "arresteePriorPeriods") {
      setArrestee({
        ...arrestee,
        PriorPeriods: value,
      });
    } else if (name === "arresteeAdditionalName1") {
      setArrestee({
        ...arrestee,
        AdditionalName1: value,
      });
    } else if (name === "arresteeAdditionalRelationship1") {
      setArrestee({
        ...arrestee,
        AdditionalRelationship1: value,
      });
    } else if (name === "arresteeAdditionalPhone1") {
      setArrestee({
        ...arrestee,
        AdditionalPhone1: value,
      });
    } else if (name === "arresteeAdditionalEmail1") {
      setArrestee({
        ...arrestee,
        AdditionalEmail1: value,
      });
    } else if (name === "arresteeAdditionalName2") {
      setArrestee({
        ...arrestee,
        AdditionalName2: value,
      });
    } else if (name === "arresteeAdditionalRelationship2") {
      setArrestee({
        ...arrestee,
        AdditionalRelationship2: value,
      });
    } else if (name === "arresteeAdditionalPhone2") {
      setArrestee({
        ...arrestee,
        AdditionalPhone2: value,
      });
    } else if (name === "arresteeAdditionalEmail2") {
      setArrestee({
        ...arrestee,
        AdditionalEmail2: value,
      });
    } else if (name === "arresteeCurrentCharges") {
      setArrestee({
        ...arrestee,
        CurrentCharges: value,
      });
    }
  };

  const onSave = async (event) => {
    event.preventDefault();

    if (!accepted) {
      setError("Please accept terms and policies.");
      return;
    }

    try {
      setError(null);
      if (currentUser.userType === 0) {

        if (!ffsr.Name || !ffsr.Phone || !ffsr.Email || !arrestee.Name || !arrestee.Birthday || !arrestee.Facility) {
          setError("Please Complete all Required Questions to Request Bids. The data won't be saved until you fill in all required fields.")
          return;
        }

        console.log("data", ffsr, arrestee, bondPromisor);
        const gcUserRef = database.ref(`FFSRT/${currentUser.UserUid}`);
        await gcUserRef.update({
          FFSR: ffsr,
          BondPromisor: isSame ? ffsr : bondPromisor,
          Arrestee: arrestee,
          IsSame: isSame,
          TermsAndPoliciesCheckBox: accepted,
        });
        toast("Profile Info Saved!");
        history.push({
          pathname: "/jobs",
          state: {
            name: arrestee.Name,
            birth: arrestee.Birthday,
            phone: ffsr.phone,
            facility: arrestee.Facility,
            isShowCongratsPage: true,
          },
        });
      } else if (currentUser.userType === 1) {
        const scUserRef = database.ref(`BCT/${currentUser.UserUid}`);
        await scUserRef.update({
          Name: name || "",
          Email: email || "",
          Phone: phone || "",
          DBA: dba || "",
          LicenseNumber: licenseNumber || "",
          Address: address || "",
          Location: location || "",
          TermsAndPoliciesCheckBox: accepted,
        });
        toast("Profile Info Saved!");
      }
    } catch (error) {
      console.log("error", error);
      setError("Ooops! Error Saving Profile Info.");
      toast("Ooops! Error Saving Profile Info.");
    }
  };

  return (
    <div className="w-full flex flex-col">
      <Navbar />
      <div className="flex flex-col items-center py-4">
        <img
          src={icon ? icon : avatar}
          alt="..."
          className="w-32 h-32 rounded-full"
        />
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleChange}
        accept=".png,.jpg,.gif,.PNG"
        className="hidden"
      />
      <button
        className="bg-primary hover:opacity-80 w-32 py-2 text-white mx-auto mb-10"
        onClick={(event) => {
          handleUpload(event);
        }}
      >
        Change Photo
      </button>
      {currentUser.userType === 0 && (
        <>
          <div className="flex justify-center items-center text-center text-primary font-bold my-8">
            <div className="w-40 mx-4">
              Complete "Arrestee's Profile"
              <img src={jobCompleted} alt="..." className="w-24 h-auto m-auto object-contain" />
            </div>
            <img src={rightArrow} alt="..." className="w-24 h-8" />
            <div className="w-40 mx-4">
              Bonding Companies Review and Submit Bids
              <img src={reviewAndSubmit} alt="..." className="w-24 h-auto m-auto object-contain" />
            </div>
            <img src={rightArrow} alt="..." className="w-24 h-8" />
            <div className="w-40 mx-4">
              Find the Lowest or Best Bid
              <img src={gcAndSc} alt="..." className="w-24 h-auto m-auto object-contain" />
            </div>
          </div>
          <div className="text-center text-primary font-bold px-4">Most Questions Optional But More Answers Equals Better Offers</div>
        </>
      )}
      {currentUser.userType === 1 && (
        <div className="flex justify-center items-center text-center text-primary font-bold my-8">
          <div className="w-40 mx-4">
            Arrestee Completes Profile
            <img src={jobCompleted} alt="..." className="w-24 h-auto m-auto object-contain" />
          </div>
          <img src={rightArrow} alt="..." className="w-24 h-8" />
          <div className="w-40 mx-4">
            Bonding Companies Review and Submit Bids
            <img src={reviewAndSubmit} alt="..." className="w-24 h-auto m-auto object-contain" />
          </div>
          <img src={rightArrow} alt="..." className="w-24 h-8" />
          <div className="w-40 mx-4">
            Arrestee's Family/Friend Finds the Lowest or Best Bid
            <img src={gcAndSc} alt="..." className="w-24 h-auto m-auto object-contain" />
          </div>
        </div>
      )}
      <div className="mx-auto w-11/12 md:w-2/4 rounded py-4 px-4 md:px-8 flex flex-col">
        {currentUser.userType === 1 && (
          <>
            <InputWrapper
              label="Bonding Company Name"
              name="name"
              value={name}
              onChangeHandler={onChangeHandler}
            />
            <label htmlFor="licenseNumber" className="block text-sm">
              Bonding Company DBA (if used):
            </label>
            <textarea
              className="mt-2 p-2 w-full h-36 border border-black-400 mb-4"
              name="dba"
              value={dba}
              placeholder=""
              id="dba"
              onChange={(event) => onChangeHandler(event)}
            />
            <InputWrapper
              label="Bonding Company Phone"
              name="phone"
              value={phone}
              onChangeHandler={onChangeHandler}
            />
            <InputWrapper
              label="Bonding Company Email"
              name="email"
              value={email}
              onChangeHandler={onChangeHandler}
            />
            <InputWrapper
              label="Bonding Company / Bondsmen Texas Bonding License Number"
              name="licenseNumber"
              value={licenseNumber}
              onChangeHandler={onChangeHandler}
            />
            <InputWrapper
              label="Bonding Company Address"
              name="address"
              value={address}
              onChangeHandler={onChangeHandler}
            />
            <label htmlFor="address" className="block text-sm">
              Primary County Served:
            </label>
            <Dropdown
              options={DATA}
              onChange={onSelect}
              value={location}
              placeholder="Please choose the location."
              className="mt-2 mb-4 w-full"
              controlClassName="h-10"
            />
            <label htmlFor="address" className="block text-md font-bold">
              Counter: {currentUser.Counter} Free Bids Remaining
            </label>
            {currentUser.Subscription && (
              <label htmlFor="address" className="block text-md font-bold">
                Subscription: {currentUser.Subscription}
              </label>
            )}
            {currentUser.Counter <= 0 && !currentUser.Subscription ? (
              <button
                className="bg-primary hover:opacity-80 w-full py-2 text-white font-bold mt-2"
                onClick={(event) => {
                  event.preventDefault();
                  history.push("/subscription");
                }}
              >
                Upgrade/Downgrade Subscription After Initial 12 Free Bids.
              </button>
            ) : (
              <>
                <button
                  className="bg-primary hover:opacity-80 w-full py-2 text-white font-bold mt-2"
                  onClick={async (event) => {
                    event.preventDefault();

                    axios
                      .post(
                        "https://us-central1-bailbondbidsnow-9ec7c.cloudfunctions.net/cancelStripeSubscription",
                        {
                          subId: currentUser.StripeSubscriptionId,
                        },
                        { headers: { "Content-Type": "application/json" } }
                      )
                      .then(async (response) => {
                        if (
                          response &&
                          response.data &&
                          response.data.success
                        ) {
                          const scUserRef = database.ref(
                            `BCT/${currentUser.UserUid}`
                          );
                          await scUserRef.update({
                            Subscription: "",
                            StripeCustomerId: "",
                            StripeSubscriptionId: "",
                            StripeItemId: "",
                            SelectedCounties: [],
                          });
                          toast("Subscription cancelled!");
                          setTimeout(() => history.go(0), 1000);
                        }
                      });
                  }}
                >
                  Cancel Subscription
                </button>
                <button
                  className="bg-primary hover:opacity-80 w-full py-2 text-white font-bold mt-2"
                  onClick={async (event) => {
                    event.preventDefault();
                    history.push("/subscription");
                  }}
                >
                  Upgrade/Downgrade Subscription After Initial 12 Free Bids.
                </button>
              </>
            )}
          </>
        )}
        {currentUser.userType === 0 && (
          <>
            <p className="text-center font-bold mt-8">
              Family/Friend Seeking Release
            </p>
            <InputWrapper
              label="Family/Friend's Name"
              name="ffsrName"
              value={ffsr.Name}
              onChangeHandler={onChangeHandler}
              required
              placeholder="Required"
            />
            <InputWrapper
              label="Family/Friend's Phone"
              name="ffsrPhone"
              value={ffsr.Phone}
              onChangeHandler={onChangeHandler}
              required
              placeholder="Required"
            />
            <InputWrapper
              label="Family/Friend's Preferred Email"
              name="ffsrEmail"
              value={ffsr.Email}
              onChangeHandler={onChangeHandler}
              required
              placeholder="Required"
            />
            <InputWrapper
              label="Family/Friend's Date of Birth"
              name="ffsrBirthday"
              value={ffsr.Birthday}
              onChangeHandler={onChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Family/Friend's Address"
              name="ffsrAddress"
              value={ffsr.Address}
              onChangeHandler={onChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Family/Friend's Driver License or ID State and Number"
              name="ffsrID"
              value={ffsr.ID}
              onChangeHandler={onChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Family/Friend's Relationship to Arrestee"
              name="ffsrRelationship"
              value={ffsr.Relationship}
              onChangeHandler={onChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Family/Friend's Current Employer"
              name="ffsrEmployeerName"
              value={ffsr.EmployeerName}
              onChangeHandler={onChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Family/Friend's Current Employer's Phone"
              name="ffsrEmployeerPhone"
              value={ffsr.EmployeerPhone}
              onChangeHandler={onChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="How Long at Current Employer"
              name="ffsrEmployeerHowLong"
              value={ffsr.EmployeerHowLong}
              onChangeHandler={onChangeHandler}
              placeholder="Optional"
            />
            <label className="block my-2">
              <input
                type="checkbox"
                name="isFFSRAvailable"
                checked={ffsr.IsAvailable}
                onChange={() =>
                  setFFSR({
                    ...ffsr,
                    IsAvailable: !ffsr.IsAvailable,
                  })
                }
              />
              &nbsp;&nbsp;&nbsp;Have Available Collateral if Required by Bonding
              Company
            </label>
            {ffsr.IsAvailable && (
              <InputWrapper
                label="If so, Please Describe Available Collateral (ie...vehicle, bank account, home, etc.)"
                name="ffsrCollateral"
                value={ffsr.Collateral}
                onChangeHandler={onChangeHandler}
                placeholder="Optional"
              />
            )}
            <InputWrapper
              label="Alternate Contact Person’s Name"
              name="ffsrAlternateName"
              value={ffsr.AlternateName}
              onChangeHandler={onChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Alternate Contact Person’s Phone"
              name="ffsrAlternatePhone"
              value={ffsr.AlternatePhone}
              onChangeHandler={onChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Alternate Contact Person’s Email"
              name="ffsrAlternateEmail"
              value={ffsr.AlternateEmail}
              onChangeHandler={onChangeHandler}
              placeholder="Optional"
            />

            <p className="text-center font-bold mt-8">
              Bond Promisor or Co-Signer (Person who promises or also promises
              to repay bond)
            </p>
            <label className="block my-2">
              <input
                type="checkbox"
                name="isSame"
                checked={isSame}
                onChange={() => {
                  setIsSame(!isSame);
                }}
              />
              &nbsp;&nbsp;&nbsp;If same person as above check here
            </label>
            {!isSame && (
              <>
                <InputWrapper
                  label="Promisor’s Name"
                  name="promisorName"
                  value={bondPromisor.Name}
                  onChangeHandler={onPromisorChangeHandler}
                  placeholder="Optional"
                />
                <InputWrapper
                  label="Promisor's Phone"
                  name="promisorPhone"
                  value={bondPromisor.Phone}
                  onChangeHandler={onPromisorChangeHandler}
                  placeholder="Optional"
                />
                <InputWrapper
                  label="Promisor's Preferred Email"
                  name="promisorEmail"
                  value={bondPromisor.Email}
                  onChangeHandler={onPromisorChangeHandler}
                  placeholder="Optional"
                />
                <InputWrapper
                  label="Promisor's Date of Birth"
                  name="promisorBirthday"
                  value={bondPromisor.Birthday}
                  onChangeHandler={onPromisorChangeHandler}
                  placeholder="Optional"
                />
                <InputWrapper
                  label="Promisor's Address"
                  name="promisorAddress"
                  value={bondPromisor.Address}
                  onChangeHandler={onPromisorChangeHandler}
                  placeholder="Optional"
                />
                <InputWrapper
                  label="Promisor's Driver License or ID State and Number"
                  name="promisorID"
                  value={bondPromisor.ID}
                  onChangeHandler={onPromisorChangeHandler}
                  placeholder="Optional"
                />
                <InputWrapper
                  label="Relationship to Arrestee"
                  name="promisorRelationship"
                  value={bondPromisor.Relationship}
                  onChangeHandler={onPromisorChangeHandler}
                  placeholder="Optional"
                />
                <InputWrapper
                  label="Promisor's Current Employer"
                  name="promisorEmployeerName"
                  value={bondPromisor.EmployeerName}
                  onChangeHandler={onPromisorChangeHandler}
                  placeholder="Optional"
                />
                <InputWrapper
                  label="Promisor's Current Employer's Phone"
                  name="promisorEmployeerPhone"
                  value={bondPromisor.EmployeerPhone}
                  onChangeHandler={onPromisorChangeHandler}
                  placeholder="Optional"
                />
                <InputWrapper
                  label="Promisor’s Time at Current Employer"
                  name="promisorEmployeerHowLong"
                  value={bondPromisor.EmployeerHowLong}
                  onChangeHandler={onPromisorChangeHandler}
                  placeholder="Optional"
                />
                <label className="block my-2">
                  <input
                    type="checkbox"
                    name="isPromisorAvailable"
                    checked={bondPromisor.IsAvailable}
                    onChange={() =>
                      setBondPromisor({
                        ...bondPromisor,
                        IsAvailable: !bondPromisor.IsAvailable,
                      })
                    }
                  />
                  &nbsp;&nbsp;&nbsp;Does Promisor Have Available Collateral if
                  Required by Bonding Company
                </label>
                {bondPromisor.IsAvailable && (
                  <InputWrapper
                    label="If so, Please Describe Available Collateral (ie...vehicle, bank account, home, etc.)"
                    name="promisorCollateral"
                    value={bondPromisor.Collateral}
                    onChangeHandler={onPromisorChangeHandler}
                    placeholder="Optional"
                  />
                )}
                <InputWrapper
                  label="Promisor's Alternate Contact Person’s Name"
                  name="promisorAlternateName"
                  value={bondPromisor.AlternateName}
                  onChangeHandler={onPromisorChangeHandler}
                  placeholder="Optional"
                />
                <InputWrapper
                  label="Promisor's Alternate Contact Person’s Phone"
                  name="promisorAlternatePhone"
                  value={bondPromisor.AlternatePhone}
                  onChangeHandler={onPromisorChangeHandler}
                  placeholder="Optional"
                />
                <InputWrapper
                  label="Promisor's Alternate Contact Person’s Email"
                  name="promisorAlternateEmail"
                  value={bondPromisor.AlternateEmail}
                  onChangeHandler={onPromisorChangeHandler}
                  placeholder="Optional"
                />
              </>
            )}

            <p className="text-center font-bold mt-8">Arrestee's Information</p>
            <InputWrapper
              label="Arrestee's Name"
              name="arresteeName"
              value={arrestee.Name}
              onChangeHandler={onArresteeChangeHandler}
              required
              placeholder="Required"
            />
            <InputWrapper
              label="Arrestee's Phone"
              name="arresteePhone"
              value={arrestee.Phone}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee's Preferred Email"
              name="arresteeEmail"
              value={arrestee.Email}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee's Date of Birth"
              name="arresteeBirthday"
              value={arrestee.Birthday}
              onChangeHandler={onArresteeChangeHandler}
              required
              placeholder="Required"
            />
            <InputWrapper
              label="Arrestee's Address"
              name="arresteeAddress"
              value={arrestee.Address}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee's Citizenship"
              name="arresteeCitizenship"
              value={arrestee.Citizenship}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee's Immigration Status"
              name="arresteeImmigrationStatus"
              value={arrestee.ImmigrationStatus}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee's Social Security Number"
              name="arresteeSSN"
              value={arrestee.SSN}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee's Driver License or ID State and Number"
              name="arresteeID"
              value={arrestee.ID}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee Relationship to You"
              name="arresteeRelationship"
              value={arrestee.Relationship}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee's Current Employer"
              name="arresteeEmployeerName"
              value={arrestee.EmployeerName}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee's Current Employer Phone"
              name="arresteeEmployeerPhone"
              value={arrestee.EmployeerPhone}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee’s Length at Current Employer"
              name="arresteeEmployeerHowLong"
              value={arrestee.EmployeerHowLong}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee’s SID Number if Known"
              name="arresteeSID"
              value={arrestee.SID}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee’s Arresting Agency/Department if Known"
              name="arresteeAgency"
              value={arrestee.Agency}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <label htmlFor="facility" className="block text-sm font-bold">
              Arrestee’s Current Jail/Detention Facility (Required):
            </label>
            <Dropdown
              options={DATA}
              onChange={onSelectFacility}
              value={arrestee.Facility}
              placeholder="Required"
              className="mt-2 mb-4 w-full"
              controlClassName="bg-yellow-200 h-10"
            />
            <InputWrapper
              label="Arrestee's Current Charge(s) if Known"
              name="arresteeCurrentCharges"
              value={arrestee.CurrentCharges}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Any Arrestee’s Known Prior Arrests if Known"
              name="arresteePriorArrests"
              value={arrestee.PriorArrests}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Any Arresttee’s Known Criminal History if Known"
              name="arresteeCriminalHistory"
              value={arrestee.CriminalHistory}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Any Arrestee’s Prior Periods of Prison Incarceration if Known"
              name="arresteePriorPeriods"
              value={arrestee.PriorPeriods}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee’s 1st Additional Contact Person’s Name"
              name="arresteeAdditionalName1"
              value={arrestee.AdditionalName1}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee’s 1st Additional Contact Person’s Relationship to arrestee"
              name="arresteeAdditionalRelationship1"
              value={arrestee.AdditionalRelationship1}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee’s 1st Additional Alternate Contact’s Person Phone"
              name="arresteeAdditionalPhone1"
              value={arrestee.AdditionalPhone1}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee’s 1st Additional Alternate Contact’s Person Email"
              name="arresteeAdditionalEmail1"
              value={arrestee.AdditionalEmail1}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee’s 2nd Additional Contact Person’s Name"
              name="arresteeAdditionalName2"
              value={arrestee.AdditionalName2}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee’s 2nd Additional Contact Person’s Relationship to arrestee"
              name="arresteeAdditionalRelationship2"
              value={arrestee.AdditionalRelationship2}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee’s 2nd Additional Alternate Contact’s Person Phone"
              name="arresteeAdditionalPhone2"
              value={arrestee.AdditionalPhone2}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
            <InputWrapper
              label="Arrestee’s 2nd Additional Alternate Contact’s Person Email"
              name="arresteeAdditionalEmail2"
              value={arrestee.AdditionalEmail2}
              onChangeHandler={onArresteeChangeHandler}
              placeholder="Optional"
            />
          </>
        )}
        <label className="block my-2">
          <input
            type="checkbox"
            name="accepted"
            checked={accepted}
            onChange={() => setAccepted(!accepted)}
          />
          &nbsp;&nbsp;&nbsp;Accept Terms & Policies
        </label>
        {accepted && <ToS />}

        {error !== null && (
          <div className="py-2 bg-red-600 w-full text-white text-center mb-3">
            {error}
          </div>
        )}
        <button
          className="bg-primary hover:opacity-80 w-full py-2 text-white font-bold"
          onClick={(event) => onSave(event)}
        >
          {currentUser.userType === 0 ? "Request Bids for Bail" : "Save"}
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProfilePage;
