import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { auth, database } from "../firebase";

const SignUp = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [type, setType] = useState("gc");
  const [error, setError] = useState(null);

  const createUserWithEmailAndPasswordHandler = async (
    event,
    email,
    password,
    confirmPwd
  ) => {
    event.preventDefault();

    if (password !== confirmPwd) {
      setError("Passwords don't match.");
      return;
    }

    try {
      const { user } = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      setError(null);

      if (type === "gc") {
        const gcUserRef = database.ref(`FFSRT/${user.uid}`);
        await gcUserRef.set({
          Email: email,
          UserUid: user.uid,
          JoinedAt: (new Date()).toISOString(),
        });
      } else if (type === "sc") {
        const scUserRef = database.ref(`BCT/${user.uid}`);
        await scUserRef.set({
          Email: email,
          UserUid: user.uid,
          Counter: 12,
          JoinedAt: (new Date()).toISOString(),
        });
      }

      setTimeout(() => history.push("/profile"), 500);
    } catch (error) {
      setError("Error Signing up with email and password");
    }

    setEmail("");
    setPassword("");
    setConfirmPwd("");
    setType("gc");
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    } else if (name === "type") {
      setType(value);
    } else if (name === "confirmPwd") {
      setConfirmPwd(value);
    }
  };

  return (
    <div className="mt-8">
      <h1 className="text-3xl mb-2 text-center font-bold">Sign Up</h1>
      <div className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
        {error !== null && (
          <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
            {error}
          </div>
        )}
        <form className="">
          <label htmlFor="userEmail" className="block">
            Email:
          </label>
          <input
            type="email"
            className="my-1 p-1 w-full border border-black-400"
            name="userEmail"
            value={email}
            placeholder="Your Email"
            id="userEmail"
            onChange={(event) => onChangeHandler(event)}
          />
          <label htmlFor="userPassword" className="block">
            Password:
          </label>
          <input
            type="password"
            className="mt-1 p-1 w-full border border-black-400"
            name="userPassword"
            value={password}
            placeholder="Your Password"
            id="userPassword"
            onChange={(event) => onChangeHandler(event)}
          />
          <label htmlFor="confirmPassword" className="block">
            Confirm Password:
          </label>
          <input
            type="password"
            className="mt-1 p-1 w-full border border-black-400"
            name="confirmPwd"
            value={confirmPwd}
            placeholder="Confirm Password"
            id="confirmPwd"
            onChange={(event) => onChangeHandler(event)}
          />
          <label htmlFor="userType" className="block">
            Type:
          </label>
          <div className="mt-1 mb-5 w-full">
            <input
              type="radio"
              id="gc"
              name="type"
              value="gc"
              defaultChecked
              onChange={(event) => onChangeHandler(event)}
            ></input>
            <label htmlFor="gc" className="ml-1">
              Family/Friend Seeking Release
            </label>
            <input
              type="radio"
              id="sc"
              name="type"
              value="sc"
              className="ml-5"
              onChange={(event) => onChangeHandler(event)}
            ></input>
            <label htmlFor="sc" className="ml-1">
              Bonding Company
            </label>
          </div>
          <button
            className="bg-primary w-full py-2 text-white"
            onClick={(event) => {
              createUserWithEmailAndPasswordHandler(
                event,
                email,
                password,
                confirmPwd
              );
            }}
          >
            Sign up
          </button>
        </form>
        <p className="text-center my-3">
          Already have an account?{" "}
          <Link to="/" className="text-primary">
            Sign in here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
